import _ from "lodash";
import fleetMgmtActionTypes from "./fleetMgmtActionTypes";
import {fleetMgmtState} from "./fleetMgmtSelectors";
import {
    validateFleetChanges,
    validateMoveFleetChanges
} from "./services/fleetMgmtService";
import errorMessages from "../../common/errorMessages";

const initialState = fleetMgmtState();

const fleetMgmtReducer = (state = initialState, action) => {
    switch (action.type) {
        case fleetMgmtActionTypes.FLEET_MGMT_QUERY_FLEETS_STARTING:
            return onQueryFleetsStarting(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_QUERY_FLEETS_SUCCESS:
            return onQueryFleetsSuccess(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_QUERY_FLEETS_ERROR:
            return onQueryFleetsError(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_QUERY_OWNERS_STARTING:
        case fleetMgmtActionTypes.FLEET_MGMT_SAVE_FLEET_STARTING:
        case fleetMgmtActionTypes.FLEET_MGMT_MOVE_FLEET_STARTING:
        case fleetMgmtActionTypes.FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_STARTING:
            return {
                ...state,
                queryRunning: true
            };
        case fleetMgmtActionTypes.FLEET_MGMT_QUERY_OWNERS_SUCCESS:
            return onQueryOwnersSuccess(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_QUERY_OWNERS_ERROR:
        case fleetMgmtActionTypes.FLEET_MGMT_SAVE_FLEET_ERROR:
        case fleetMgmtActionTypes.FLEET_MGMT_MOVE_FLEET_ERROR:
        case fleetMgmtActionTypes.FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_ERROR:
            return {
                ...state,
                queryRunning: false
            };
        case fleetMgmtActionTypes.FLEET_MGMT_SAVE_FLEET_SUCCESS:
            return {
                ...state,
                queryRunning: true,
                openSaveFleet: false,
                openToggleFleetConfirmation: false
            };
        case fleetMgmtActionTypes.FLEET_MGMT_CHANGE_FLEET_DATA:
            return onChangeFleet(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_CHANGE_MOVE_FLEET_DATA:
            return onChangeMoveFleet(state, action);
        case fleetMgmtActionTypes.FLEET_MGMT_OPEN_SAVE_FLEET_DIALOG:
            return {
                ...state,
                fleetToSave: action.fleetToSave,
                errorsOnFleetSave: validateFleetChanges(action.fleetToSave),
                warningDatavanOnFleetSave:false,
                datavanNameHelperText: 'Datavan',
                openSaveFleet: true
            };
        case fleetMgmtActionTypes.FLEET_MGMT_CLOSE_SAVE_FLEET_DIALOG:
            return {
                ...state,
                openSaveFleet: false,
                errorsOnFleetSave: {}
            };
        case fleetMgmtActionTypes.FLEET_MGMT_OPEN_TOGGLE_FLEET_ACTIVE_DIALOG:
            return {
                ...state,
                openToggleFleetConfirmation: true,
                fleetToSave: action.fleet
            };
        case fleetMgmtActionTypes.FLEET_MGMT_CLOSE_TOGGLE_FLEET_ACTIVE_DIALOG:;
            return {
                ...state,
                openToggleFleetConfirmation: false
            };
        case fleetMgmtActionTypes.FLEET_MGMT_OPEN_MOVE_FLEET_DIALOG:
            return {
                ...state,
                fleetToMove: action.fleet,
                errorsOnFleetMove: validateMoveFleetChanges(action.fleet),
                openMoveFleetDialog: true
            };
        case fleetMgmtActionTypes.FLEET_MGMT_CLOSE_MOVE_FLEET_DIALOG:
            return {
                ...state,
                openMoveFleetDialog: false
            }
        case fleetMgmtActionTypes.FLEET_MGMT_MOVE_FLEET_SUCCESS:
            return {
                ...state,
                queryRunning: false,
                openMoveFleetDialog: false
            };
        case fleetMgmtActionTypes.FLEET_MGMT_SET_ROWS_PER_PAGE:
            return {
                ...state,
                rowsPerPage: action.rowSize,
            };
        case fleetMgmtActionTypes.FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_SUCCESS:
            return onQueryAssetIdSuccess(state, action);
        default:
            return state;
    }
};

const onQueryAssetIdSuccess = (state, action) => {
    const shouldWarn = _.isNil(action.assetInfo) || _.isNil(action.assetInfo.assetId);
    return {
        ...state,
        warningDatavanOnFleetSave:shouldWarn,
        datavanNameHelperText:shouldWarn? errorMessages.WARNING_SAVING_FLEET_NEXTIER_WITHOUT_ASSETID_INLINE : 'Datavan',
        queryRunning: false
    };
};

const onQueryOwnersSuccess = (state, action) => {

  const owners = _.orderBy(action.queryResults.ownersWithApiKey, 'ownerName');

  return {
    ...state,
    queryRunning: false,
    owners: owners
  };
};

const onQueryFleetsStarting = (state, action) => {
    return {
        ...state,
        queryRunning: true,
        fleets: [],
    };
};

const onQueryFleetsSuccess = (state, action) => {
    return {
        ...state,
        queryRunning: false,
        fleets: action.queryResults.fleetManagement,
    };
};

const onQueryFleetsError = (state, action) => {
    return {
        ...state,
        queryRunning: false,
        fleets: [],
    };
};
const onChangeFleet = (state, action) => {
    const fleetToSave = _.cloneDeep(state.fleetToSave);
    fleetToSave[action.property] = action.value;

    return {
        ...state,
        fleetToSave: fleetToSave,
        errorsOnFleetSave: validateFleetChanges(fleetToSave)
    };
};
const onChangeMoveFleet = (state, action) => {
    const fleetToMove = _.cloneDeep(state.fleetToMove);
    fleetToMove[action.property] = action.value;

    return {
        ...state,
        fleetToMove: fleetToMove,
        errorsOnFleetMove: validateMoveFleetChanges(fleetToMove),
    };
};

export default fleetMgmtReducer;

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";

import {
  translateMdtYAxisId,
  getYAxisFromMdtId,
  modifiers,
  X_AXIS_IDS,
  Y_AXIS_IDS,
} from "./chartSettings";
import {SciChartSurface} from "scichart/Charting/Visuals/SciChartSurface";
import {NumericAxis} from "scichart/Charting/Visuals/Axis/NumericAxis";
import {XyDataSeries} from "scichart/Charting/Model/XyDataSeries";

import {FastLineRenderableSeries} from "scichart/Charting/Visuals/RenderableSeries/FastLineRenderableSeries"

import {ZoomPanModifier} from "scichart/Charting/ChartModifiers/ZoomPanModifier";
import {MouseWheelZoomModifier} from "scichart/Charting/ChartModifiers/MouseWheelZoomModifier";
import {CursorModifier} from "scichart/Charting/ChartModifiers/CursorModifier";
import {RubberBandXyZoomModifier} from "scichart/Charting/ChartModifiers/RubberBandXyZoomModifier";
import {EAxisAlignment} from 'scichart/types/AxisAlignment';
import {YAxisDragModifier} from "scichart/Charting/ChartModifiers/YAxisDragModifier";
import {XAxisDragModifier} from "scichart/Charting/ChartModifiers/XAxisDragModifier";
import {LegendModifier} from "scichart/Charting/ChartModifiers/LegendModifier";
import {ZoomExtentsModifier} from "scichart/Charting/ChartModifiers/ZoomExtentsModifier";
import {RolloverModifier} from "scichart/Charting/ChartModifiers/RolloverModifier";
import {NumberRange} from "scichart/Core/NumberRange";
import { mdtPalette } from "../../../common/styles/mdtPalette";
import {ENumericFormat} from "scichart/types/NumericFormat";
import {SensorValueTickProvider} from "./sensorValueTickProvider";
import {ELineDrawMode} from "scichart/Charting/Drawing/WebGlRenderContext2D";

const VIBRATION_SENSOR_PREFIX = 'vibration';

/**
 * Load chart surface
 */
async function initChart(chartRef) {
  const {sciChartSurface, wasmContext} = await SciChartSurface.create(
    "chart-root"
  );

  sciChartSurface.background = mdtPalette().materialUI.palette.background.paper;
  sciChartSurface.viewportBorder = {
    borderLeft: 1,
    borderRight: 1,
    borderTop: 0,
    borderBottom: 1,
    color: mdtPalette().categories.category1
  };

  chartRef.sciChartSurface = sciChartSurface;
  chartRef.wasmContext = wasmContext;

  return {sciChartSurface, wasmContext}
}

/**
 * Creates initial xAxis, only 'primary' is visible
 *
 * @param onPrimaryXAxisChange is a callback function to handle xAxis visibleRange changes
 */
const initXAxis = (surface, wasm, onPrimaryXAxisChange, onSecondaryXAxisChange) => {
  if (_.isNil(surface)) return;
  if (_.isNil(wasm)) return;
  // create all the xAxis but only show the primary to start
  X_AXIS_IDS.forEach((pos, i) => {
    const xAxis = new NumericAxis(wasm);
    xAxis.id = pos;
    xAxis.isVisible = i === 0;
    // xAxis.isPrimaryAxis = i === 0;
    // xAxis.axisTitleStyle.color= '#ff99ff';

    xAxis.labelStyle = {
      // same style as UnitCharts
      color: '#BDBDBD', // Material UI Grey 400, because mdtPallete().typography.color is still too bright
      fontFamily: 'Roboto',
      fontSize: 14, // larger than our normal charts
      fontWeight: 'bold'
    };

    xAxis.labelProvider.formatLabel = (epochSeconds) => {
      return new Date(epochSeconds * 1000).toLocaleTimeString("en-US",
        {
          // year: "numeric",
          month: "numeric",
          day: "numeric"
        });
    };

    /*
    Use the eventHandlers as the trigger to recalculate sensor stats (min/max/mean)
     */
    xAxis.visibleRangeChanged.subscribe((args) => {
      const min = Math.ceil(args.visibleRange.min);
      const max = Math.floor(args.visibleRange.max);

      if (xAxis.id === 'primary') {
        primaryXaxisDebounce(onPrimaryXAxisChange, xAxis.id, min, max);
      }
      if (xAxis.id === 'secondary') {
        secondaryXaxisDebounce(onSecondaryXAxisChange, xAxis.id, min, max);
      }
    });
    surface.xAxes.add(xAxis);
  });
};

/**
 * Creates initial yAxis, only 1 on left is initially visible
 *
 */
const initYAxis = (surface, wasm) => {
  if (_.isNil(surface)) return;
  if (_.isNil(wasm)) return;
  // create all the yAxis but only show the first one
  Y_AXIS_IDS.forEach((pos, i) => {
    const yAxis = new NumericAxis(wasm);
    yAxis.isVisible = i === 0;
    yAxis.id = pos;
    // yAxis.axisTitle = `${"-".repeat(5)}`;
    //todo: remove yaxis titles once happy they are ok
    // yAxis.axisTitle = pos;
    yAxis.axisAlignment = i < 2 ? EAxisAlignment.Left : EAxisAlignment.Right;

    // Don't use a specific format (Decimal_0) because we need decimal values when zooming
    yAxis.labelProvider.numericFormat = ENumericFormat.NoFormat;
    yAxis.tickProvider = new SensorValueTickProvider(wasm);

    yAxis.labelStyle = {
      // same style as UnitCharts
      color: '#BDBDBD', // Material UI Grey 400, because mdtPallete().typography.color is still too bright
      fontFamily: 'Roboto',
      fontSize: 14, // larger than our normal charts
      fontWeight: 'bold'
    };

    //todo: should we put a limit on number of yAxis?
    surface.yAxes.add(yAxis);
  });

};

/**
 * Add chart modifiers as defined in settings
 */
const addModifiers = (surface, modifiers) => {

  if (_.isNil(surface)) return;
  if (_.isNil(modifiers)) return;

  // console.log("add modifiers");

  surface.chartModifiers.clear();
  modifiers.forEach(m => {
    if (m.isEnabled) {
      switch (m.name) {
        case "MouseWheelZoomModifier":
          surface.chartModifiers.add(new MouseWheelZoomModifier(m.options));
          break;
        case "ZoomPanModifier":
          surface.chartModifiers.add(new ZoomPanModifier(m.options));
          break;
        case "YAxisDragModifier":
          surface.chartModifiers.add(new YAxisDragModifier(m.options));
          break;
        case "XAxisDragModifier":
          surface.chartModifiers.add(new XAxisDragModifier(m.options));
          break;
        case "LegendModifier":
          surface.chartModifiers.add(new LegendModifier(m.options));
          break;
        case "ZoomExtentsModifier":
          let modifier = new ZoomExtentsModifier();
          modifier.modifierDoubleClick = (args) => {
            resetZoom(surface);
          }
          surface.chartModifiers.add(modifier);
          break;
        case "RubberBandXyZoomModifier":
          surface.chartModifiers.add(new RubberBandXyZoomModifier(m.options));
          break;
        case "RolloverModifier":
          surface.chartModifiers.add(new RolloverModifier({
            ...m.options,
            xAxisId: surface.xAxes.get(0).id,
            yAxisId: surface.yAxes.get(0).id
          }));
          break;
        case "CursorModifier":
          // new CursorTooltipSvgAnnotation()
          let cursorMod = new CursorModifier({
            ...m.options,
            xAxisId: surface.xAxes.get(0).id,
            yAxisId: surface.yAxes.get(0).id
          });
          // cursorMod.tooltipSvgTemplate = customTooltipTemplate;
          surface.chartModifiers.add(cursorMod);
          break;
      }
    }
  });
};

/**
 *
 */
const syncYAxisVisibilityAndRotate = (surface, rotate) => {
  if (_.isNil(surface)) return;
  // find the used ones
  const usedYaxis = new Set(surface.renderableSeries.asArray().filter(s => s.isVisible).map(series => series.yAxisId));

  // remove the others
  surface.yAxes.asArray().forEach((y, i) => {
    y.isVisible = usedYaxis.has(y.id) !== y.isVisible ? usedYaxis.has(y.id) : usedYaxis.has(y.id);
    if(rotate === true){
      y.axisAlignment = i < 2 ? EAxisAlignment.Top : EAxisAlignment.Bottom;
      y.flippedCoordinates = true;
    } else if (rotate === false) {
      y.axisAlignment = i < 2 ? EAxisAlignment.Left : EAxisAlignment.Right;
      y.flippedCoordinates = false;
    }
  });
};

const calculateYAxisRanges = (surface) => {
  // resize all y axes to fit their visible data series with a 5% padding
  const usedYaxis = new Set(surface.renderableSeries.asArray().filter(s => s.isVisible).map(series => series.yAxisId));
  usedYaxis.forEach(axisId => {
    // determine range of values for this axis
    const axis = surface.getYAxisById(axisId);

    // determine combined range for all series of this axis
    let min = null;
    let max = null;
    surface.renderableSeries.asArray().filter(s => s.yAxisId === axisId && s.isVisible === true).forEach(series => {
      const yValues = series.getYRange(series.getXRange(), false);
      // yValues can be undefined at the start
      if (!_.isNil(yValues)) {
        if (_.isNil(min)) {
          min = yValues.min;
        }
        else if (yValues.min < min) {
          min = yValues.min;
        }
        if (_.isNil(max)) {
          max = yValues.max;
        }
        else if (yValues.max > max) {
          max = yValues.max;
        }
      }
    });

    // pad the range to make things pretty
    const padding = (max - min) * 0.05;
    // TODO: we should round to a nice round number (based on the value, ie. 1805 -> 2000)
    //       - wait until we add TickProviders and all that logic can live together -- FL
    min = Math.floor(min - padding);
    max = Math.ceil(max + padding);
    // console.log(`Resizing yAxisId=${axisId} to fit data: [${min}, ${max}]`);
    axis.visibleRange = new NumberRange(min, max);
  });
}

/**
 *
 */
const syncXAxisVisibilityAndRotate = (surface, rotate) => {
  if (_.isNil(surface)) return;
  // find the used ones
  const usedXaxis = new Set(surface.renderableSeries.asArray().filter(s => s.isVisible).map(series => series.xAxisId));
  surface.xAxes.asArray().forEach(x => {
    x.isVisible = usedXaxis.has(x.id);
    if(rotate === true) {
      x.axisAlignment = EAxisAlignment.Left;
    } else if (rotate === false) {
      x.axisAlignment = EAxisAlignment.Bottom;
    } 
  });
};

const updateAxes = (surface, rotate) => {
  syncXAxisVisibilityAndRotate(surface, rotate);
  syncYAxisVisibilityAndRotate(surface, rotate);
  calculateYAxisRanges(surface);
}

const resetZoom = (surface) => {
  surface.zoomExtentsX();
  updateAxes(surface);
}

/**
 *
 * @returns an object of {}'dataSeriesName':[renderableSeries]}
 */
const getRenderableSeriesBySeriesName = (surface, xAxisId) => {
  /**
   * Get all the renderableSeries for the xAxisId (primary/secondary)
   * and group them by their DataSeriesName {truck alias (uom)}
   */
  const results = surface.renderableSeries.asArray().filter(s => s.xAxisId === xAxisId);
  return _.groupBy(results, (item) => item.getDataSeriesName());
};

/**
 *
 */
const createDataSeries = (wasm, name, xValues, yValues) => {
  if (_.isNil(wasm)) return;
  // the dataSeriesName is assumed to be `Truck alias (uom)`
  return new XyDataSeries(wasm, {
    xValues: xValues,
    yValues: yValues,
    dataSeriesName: name,
    containsNaN: true
  });
};

/**
 * generate unique id for series based on truck, sensor alias and sensor uom.
 */
const seriesPK = (truck, sensor) => {
  return `${truck.name} ${sensor.alias} (${sensor.uom})`;
};

/**
 *
 */
const createRenderableSeries = (surface, wasm, xAxisId, truck, sensor, config, closeNaNGap, dataSeries, rotate) => {
  if (_.isNil(surface)) return;
  // console.log(`CreateRenderableSeries: ${seriesPK(truck, sensor)}`);

  const seriesOption = {
    opacity: 0.8,
    zeroLineY: 0,
    strokeThickness: 1,
    stroke: config.color,
    fill: config.color,

    xAxisId: xAxisId,
    yAxisId: translateMdtYAxisId(config.yAxisId),
    isVisible: config.isVisible,

    dataSeries: dataSeries,
    drawNaNAs: closeNaNGap ? ELineDrawMode.PolyLine : ELineDrawMode.DiscontinuousLine
  };
  const renderable = new FastLineRenderableSeries(wasm, seriesOption);

  updateSeriesForRolloverColor(renderable, rotate);

  //todo: here would be a good place to do a size check, for say ..  performance or subscription reasons
  surface.renderableSeries.add(renderable);

  // make sure Axis are visible
  getYAxisFromMdtId(surface, config.yAxisId).isVisible = (config.isVisible);
};

/**
 *
 */
const replaceRenderableSeriesData = (surface, wasm, series, dataSeriesName, xValues, yValues, zoomLevel, closeNaNGap) => {
  if (_.isNil(surface)) return;
  if (_.isNil(wasm)) return;

  const newDataSeries = createDataSeries(wasm, dataSeriesName, xValues, yValues);

  const newVisibleMin = Math.ceil(surface.getXAxisById(series.xAxisIdProperty).visibleRange.min);
  const newVisibleMax = Math.floor(surface.getXAxisById(series.xAxisIdProperty).visibleRange.max);
  surface.renderableSeries.items.forEach(series => {
    series.drawNaNAs = closeNaNGap? ELineDrawMode.PolyLine : ELineDrawMode.DiscontinuousLine;
  });

  // TODO: need to debug more to better optimize this
  // This works but we're separating pan forward and pan backwards actions when ideally they should
  // be handled the same 
  // This might have to do with the fact that when you pan backwards, we'll 99% of the time be loading in 
  // new data, causing data changes and thus causing the 'xValues' to change
  // However, when panning forward, that's not always the case - you end up hitting the future where there
  // is no data and thus we won't even get here
  // Can be related to when we do pan forward, sometimes we don't load all the data
  if (newVisibleMin !== xValues[0] && newVisibleMax !== xValues[xValues.length - 1]) {
    updateZoomExtentsRange(surface, newVisibleMin, newVisibleMax);
  } else {
    // Otherwise set the visible range to the loaded data  
    changeXAxisVisibleRange(surface, xValues[0], xValues[xValues.length - 1], zoomLevel);
  }
  
  // make sure to free up wasm memory
  series.dataSeries.delete();
  series.dataSeries = newDataSeries;
};

const changeXAxisVisibleRange = (surface, min, max, zoomLevel) => {

  // This is the number of hours the current data is visible
  // This is our visible data window
  let zoomLevelSeconds = zoomLevel * 60;

  // Calculate diff between max and min
  let timeRange = max - min;
  let visibleMin = min;
  let visibleMax = max;

  // If the new min max range is outside of the zoom level window
  // Change it so it matches the zoom level hours
  if (timeRange > zoomLevelSeconds) {
    visibleMin = min;
    visibleMax = min + zoomLevelSeconds;
  }

  surface.xAxes.asArray()
    .filter(ax => ax.id === 'primary')
    .map(x => {
      x.visibleRange = new NumberRange(min, max);
      if (timeRange >= zoomLevelSeconds) {
        x.zoomExtentsRange = new NumberRange(visibleMin, visibleMax);
      }
    });
};

const updateZoomExtentsRange = (surface, visibleMin, visibleMax) => {

  surface.xAxes.asArray()
    .filter(ax => ax.id === 'primary')
    .map(x => {
      x.zoomExtentsRange = new NumberRange(visibleMin, visibleMax);
    });
}

/**
 *
 */
const onDataChange = (surface, wasm, xAxisId, definition, data, onXAxisChange, zoomLevel, closeNaNGap, rotate) => {
  if (_.isNil(surface)) return;
  if (_.isNil(wasm)) return;

  // console.log(`onDataChange: ${xAxisId}`);
  let haveSeriesChanged = false;

  // data has changed for the primary xAxis
  // get all renderable series on the primary xAxis
  // grouped by DataSeriesName
  const seriesBySeriesName = getRenderableSeriesBySeriesName(surface, xAxisId);

  // processing in DisplayOrder
  // check for an existing renderableSeries
  // if not found create a new renderable series
  // if found update the data and remove the renderableSeries from the map
  // when done delete any remaining renderableSeries as they are no longer needed. ie) user removed a sensor
  definition.displayOrder.forEach((idx) => {
    const curTruck = definition.trucks[idx];
    const curSensor = definition.sensors[idx];
    const curConfig = definition.config[idx];
    const curPK = seriesPK(curTruck, curSensor);

    const yValueLen = _.isNil(data.yValues) ? 0 : data.yValues[idx].length;

    if (!seriesBySeriesName.hasOwnProperty(curPK)) {
      //only create renderableSeries when there is data available for the sensor
      if (yValueLen > 0) {
        createRenderableSeries(surface,
          wasm,
          xAxisId,
          curTruck,
          curSensor,
          curConfig,
          closeNaNGap,
          createDataSeries(wasm,
            curPK,
            data.xValues,
            data.yValues[idx]
          ),
          rotate 
        );
        haveSeriesChanged = true;
      }
      return;
    }

    // the renderableSeries already exists
    // so replace the data
    const curSeries = seriesBySeriesName[curPK];
    curSeries.forEach((rs) => {
      if (yValueLen > 0) {
        replaceRenderableSeriesData(surface,
          wasm,
          rs,
          curPK,
          data.xValues,
          data.yValues[idx],
          zoomLevel,
          closeNaNGap
        );
        haveSeriesChanged = true;

        // remove processed renderableSeries
        delete seriesBySeriesName[curPK];
      }
    });

  });

  // any renderableSeries left should be removed
  for (const [dataSeriesName, renderableSeries] of Object.entries(seriesBySeriesName)) {
    renderableSeries.forEach((rs) => {
      // console.log(`Begin deleting renderableSeries for ${dataSeriesName}`);
      // delete data series to free up memory
      try {
        rs.dataSeries.delete();
      } catch (err) {
        // console.error(err.message);
      }
      surface.renderableSeries.remove(rs);
      // console.log(`End deleting renderableSeries for ${dataSeriesName}`);
    });
  }

  // reset the zoom then apply any custom axis visibility or ranges
  resetZoom(surface);

  // if data has changed then we need to trigger a stats update
  if (haveSeriesChanged) {
    const range = surface.getXAxisById(xAxisId).visibleRange;
    // DOC - no need to debounce because this is a one-time event
    onXAxisChange(xAxisId, range.min, range.max);
  }
};

/**
 * effect for when config changed
 */
const onConfigChange = (surface, xAxisId, definition, rotate) => {
  if (_.isNil(surface)) return;
  if (_.isNil(definition)) return;
  // console.log(`onConfigChange: ${xAxisId}`);


  // only config has changed
  const seriesBySeriesName = getRenderableSeriesBySeriesName(surface, xAxisId);

  //todo: collect sensor related metrics here!
  /*
  As we process the configuration we can track the popular sensors
  and the popular trucks. We have the entire chart definition so
  can track the timeframe users are looking at. The chart name is
  also here so we can see which of the chart definitions are most popular.
   */
  definition.displayOrder.forEach((idx) => {
    const curTruck = definition.trucks[idx];
    const curSensor = definition.sensors[idx];
    const curConfig = definition.config[idx];
    const curPK = seriesPK(curTruck, curSensor);

    if (seriesBySeriesName.hasOwnProperty(curPK)) {
      const curRS = seriesBySeriesName[curPK][0];
      curRS.yAxisId = translateMdtYAxisId(curConfig.yAxisId);
      curRS.isVisible = curConfig.isVisible;
      curRS.stroke = curConfig.color;
      curRS.fill = curConfig.color;

      if (curSensor.alias.toLowerCase().startsWith(VIBRATION_SENSOR_PREFIX)){
        curRS.pointMarker.fill = curConfig.color;
        curRS.pointMarker.stroke = curConfig.color;
      }

      // update modifier props
      updateSeriesForRolloverColor(curRS, rotate);
    }
  });

  updateAxes(surface, rotate);
};

/**
 * Updates a series RolloverModifier color for readability.
 * To be called when a series is created or changes color.
 */
const updateSeriesForRolloverColor = (renderableSeries, rotate) => {
  const darkColor = shade(renderableSeries.stroke, -0.33);
  renderableSeries.rolloverModifierProps.tooltipColor = darkColor;
  renderableSeries.rolloverModifierProps.markerColor = darkColor;
  if (rotate) {
    const x = 10;
    const y = 0;
    renderableSeries.rolloverModifierProps.tooltipTemplate =
    (id, tooltipProps, seriesInfo, updateSize) => {
      // Width and Height of the tooltip overall
      const width = 100; // in %
      const height = 33; // in px - same height as landscape view ones

      // Width of the Sensor Name
      const nameWidth = 65; // in %
      // Width of value + uom
      const valueUomWidth = 35; // in %

      // management wants values with 4 significant digits
      const value = roundToFourSignificantDigits(seriesInfo.y1);

      // add UOM to lessen confusion, it is at the end of the series name
      const seriesNameParts = tooltipProps.seriesName.replaceAll('(', ' ').replaceAll(')', ' ').trim().split(' ');
      const uom = seriesNameParts.pop();
      const sensorName = _.join(_.slice(seriesNameParts, 1, seriesNameParts.length-1), ' ');

      // Not using the isVisible value on the renderableSeries here because that is only for the series visibility as a whole
      // We need to know if the mouse is currently rolling over or not so we can show/hide the tooltips as needed
      const visibility = _.isNil(seriesInfo.x1) ? 'collapse' : 'visible';
      const display = _.isNil(seriesInfo.x1) ? 'none' : 'flex';

      const parentContainerStyle = ` 
        width:${width}%;
        height:${height}px;
        background:${darkColor};
        visibility:${visibility};
        display:${display};
        flex-flow:column nowrap;
        left:${x}px;
        top:${y}px;
        position:relative;
        justify-content:center;
        margin-top:5px;
        align-items:center;
        font-size:1rem;
        font-weight:400;
        border-radius:3px;
        padding:8px`;

      const childContainerStyle = `
        display:flex;
        flex-flow:row nowrap;
        justify-content:flex-end;
        width:100%;
        padding-left:2px;
        padding-right:2px`;

      const sensorNameStyle = `
        display:inline-block;
        width:${nameWidth}%;
        max-width:${nameWidth}%;
        text-overflow:ellipsis;
        white-space:nowrap;
        overflow:hidden`;
        
      const valueUomStyle = `
        width:${valueUomWidth}%;
        max-width:${valueUomWidth}%;
        display:flex;
        flex-flow:row nowrap;
        justify-content:flex-end`;

      return `<div style="${parentContainerStyle}">
                <div style="${childContainerStyle}">
                  <div style="${sensorNameStyle}">${sensorName}</div> 
                  <div style="${valueUomStyle}">${value} ${uom}</div>
                </div>
              </div>`;
    };
  } else {
    renderableSeries.rolloverModifierProps.tooltipTemplate = null;
  }
};

/**
 * Round to four significant digits: 1234, 123.4, 12.34, 1.234, or 0.1234
 * @param value Sensor value
 * @return {number} Rounded sensor value
 */
const roundToFourSignificantDigits = (value) => {
  if (value >= 1000) {
    return _.round(value, 0);
  }
  else if (value >= 100) {
    return _.round(value, 1);
  }
  else if (value >= 10) {
    return _.round(value, 2);
  }
  else if (value >= 1) {
    return _.round(value, 3);
  }
  else {
    return _.round(value, 4);
  }
}

/**
 * Naively lighten or darken a hex color.
 * @param color Hex color string
 * @param ratio ratio to lighten (>0) or darken (<0)
 * @return {string} Modified hex color string
 */
const shade = (color, ratio) => {
  const normalize = (value) => {
    if (value > 255) return 255;
    else if (value < 0) return 0;
    return value;
  }
  const num = parseInt(color.slice(1), 16);
  let r = (num >> 16);
  let b = (num >> 8) & 0x00FF;
  let g = (num & 0x0000FF);
  r = normalize(r + (r * ratio));
  b = normalize(b + (b * ratio));
  g = normalize(g + (g * ratio));
  const str = (g | (b << 8) | (r << 16)).toString(16);
  return "#" + ("000000" + str).substring(str.length, str.length + 6);
}

const primaryXaxisDebounce = _.debounce((cb, axisId, min, max) => cb(axisId, min, max), 500);
const secondaryXaxisDebounce = _.debounce((cb, axisId, min, max) => cb(axisId, min, max), 500);

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Chart = (props) => {
  /**
   * Required props for this component:
   *  chartDefinition: {
   *    primary: {
   *      displayOrder: [2, 0, 1]
   *      trucks:[{id: , pid: , name: , }],
   *      sensors:[{sensorSetId:, alias:, uom:}],
   *      config:[{yAxisId: 1, color:#hex , isVisible: true}],
   *    }
   *  }
   *
   *  NOTE:  the values in the displayOrder array are the index values into the other arrays.
   *
   *  data: {
   *    primary: {
   *      xValues:[],
   *      yValues:[[],[],[]]
   *    }
   *  }
   *
   *  stats: {
   *    primary: [
   *      {min: 0,
   *      mean: 5,
   *      max: 6}
   *   ]
   *  }
   */
  const [chart, setChart] = useState({});
  const [chartLoaded, setChartLoaded] = useState(false);

  /**
   * Initialize the chart
   */
  useEffect(() => {
    // this variable is a dictionary so that we can use its properties byReference
    // rather than byValue.
    let chartRef = {};
    // Used to help fix an issue with "updating an unmounted component" warning from React
    let mounted = true;

    (async () => {
      // console.log('init chart');
      // initialize the chart surface
      await initChart(chartRef);
      initXAxis(chartRef.sciChartSurface, chartRef.wasmContext, props.onPrimaryXAxisChange, props.onSecondaryXAxisChange);
      initYAxis(chartRef.sciChartSurface, chartRef.wasmContext);
      if (mounted) {
        setChart(chartRef);
        setChartLoaded(true);
      }
    })();

    // returns a callback that is used to perform cleanup
    return () => {
      mounted = false;
      if (chartRef.sciChartSurface) {
        chartRef.sciChartSurface.delete();
      } else {
        // console.warn('empty chartRef surface');
      }
    };
  }, []);

  /**
   * Load chart modifiers
   */
  useEffect(() => {

    try {
      addModifiers(chart.sciChartSurface, modifiers(props.rotate));
    } catch (err) {
      // console.error(`addModifiers: ${err.message}`);
    }
  }, [chartLoaded, props.rotate]);

  /**
   * Update the chart based on changes in the Legend (visibility, axis positions)
   */
  useEffect(() => {
    try {
      onConfigChange(chart.sciChartSurface, X_AXIS_IDS[0], props.primaryDefinition);
    } catch (err) {
      // console.error(`PrimaryConfig: ${err.message}`);
    }
  }, [chartLoaded, props.primaryDefinition.config, props.rotate]);

  /**
   *
   */
  useEffect(() => {
    try {
      onDataChange(chart.sciChartSurface,
        chart.wasmContext,
        X_AXIS_IDS[0],
        props.primaryDefinition,
        props.primaryData,
        props.onPrimaryXAxisChange,
        props.zoomLevel,
        props.closeNaNGap,
        props.rotate);
    } catch (err) {
      // console.error(`PrimaryData: ${err.message}`);
    }
  }, [chartLoaded, props.primaryData, props.closeNaNGap, props.rotate]);

  /**
  *Swapping between axes
  */
  useEffect(() => {
    try {
      onConfigChange(chart.sciChartSurface, X_AXIS_IDS[0], props.primaryDefinition, props.rotate);
    } catch (err) {
      console.error(`PrimaryConfig: ${err.message}`);
    }
  }, [chartLoaded, props.primaryDefinition.config, props.rotate]);

  /**
   *
   */
  useEffect(() => {
    // Disabled to avoid redraws as we don't yet use a secondary axis
    // try {
    //   onConfigChange(chart.sciChartSurface, X_AXIS_IDS[1], props.secondaryDefinition);
    // } catch (err) {
    //   console.log(`SecondaryConfig: ${err.message}`);
    // }
  }, [chartLoaded, props.secondaryDefinition.config]);

  /**
   *
   */
  useEffect(() => {
    // Disabled to avoid redraws as we don't yet use a secondary axis
    // try {
    //   onDataChange(chart.sciChartSurface,
    //     chart.wasmContext,
    //     X_AXIS_IDS[1],
    //     props.secondaryDefinition,
    //     props.secondaryData,
    //     props.onSecondaryXAxisChange);
    //
    // } catch (err) {
    //   console.log(`SecondaryData: ${err.message}`);
    // }
  }, [chartLoaded, props.secondaryData]);

  return (
    <div>
      <div id="chart-root" style={props.style}/>
    </div>
  );

};

Chart.propTypes = {
  zoomLevel: PropTypes.number,
  rotate: PropTypes.bool
}

export default Chart;

import _ from 'lodash';

const fleetMgmtState = state => {
    return {
        queryRunning: _.isNil(state?.queryRunning) ? false : state.queryRunning,
        fleets: _.isNil(state) ? [] : state.fleets,
        rowsPerPage: _.isNil(state) ? 25 : state.rowsPerPage,
        rowsPerPageOptions: [25, 50, 100],
        fleetToSave: _.isNil(state) ? {} : state.fleetToSave,
        openSaveFleet: _.isNil(state) ? false : state.openSaveFleet,
        fleetToMove: _.isNil(state) ? {} : state.fleetToMove,
        errorsOnFleetSave: _.isNil(state) ? {} : state.errorsOnFleetSave,
        openToggleFleetConfirmation: _.isNil(state) ? false : state.openToggleFleetConfirmation,
        openMoveFleetDialog: _.isNil(state) ? false : state.openMoveFleetDialog,
        availableOwners: _.isNil(state) ? [] : state.availableOwners,
        errorsOnFleetMove: _.isNil(state) ? {} : state.errorsOnFleetMove,
        owners: _.isNil(state) ? [] : state.owners,
        openCopyToClipBoardNotification: _.isNil(state) ? false : state.openCopyToClipBoardNotification,
        datavanNameHelperText: _.isNil(state) ? 'Datavan' : state.datavanNameHelperText,
        warningDatavanOnFleetSave: _.isNil(state) ? false : state.warningDatavanOnFleetSave
    };
};

export {fleetMgmtState};
import React, {Component} from 'react';
import _ from "lodash";
import {compose, withProps} from "recompose";
import {connect} from "react-redux";
import {Box, Dialog, DialogContent} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import {fleetMgmtState} from "../../../state/displays/fleetManagement/fleetMgmtSelectors";
import * as fleetMgmtActions from "../../../state/displays/fleetManagement/fleetMgmtActions";
import ComponentTypes from "../../componentTypes";
import {AutoCompleteMDT} from "../../controls/mdtMuiControls";
import getDialogStyles from "../../common/styles/dialogStyles";
import {fleetTypes, getFleetTypeNameByType} from "../../../state/common/services/fleetTypeService";
import {fleetDivisions} from "../../../state/common/services/fleetDivisionService";
import {appState as applicationState} from "../../../state/app/appSelectors";

const divisionOptions = Object.values(fleetDivisions).map(option => {
    return {
        label: option
    };
});
const typeOptions = Object.values(fleetTypes).map(type => {
    return {
        label: getFleetTypeNameByType(type),
        value: type
    };
});
const dialogStyles = getDialogStyles();

const styles = {
    formField: {
        marginTop: 2
    }
};

class SaveFleetDialog extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Dialog
                open={this.props.openSaveFleet}
                disableEscapeKeyDown>
                <DialogTitle>{_.isNil(this.props.fleetToSave.id) ? 'Create' : 'Edit'} Fleet</DialogTitle>
                <DialogContent>
                    <TextField
                        sx={styles.formField}
                        autoFocus
                        variant='standard'
                        size='small'
                        helperText="Fleet Name"
                        inputProps={{maxLength: 255}}
                        value={this.props.fleetToSave.fleetName}
                        onChange={(event) => {
                            this.props.onChangeFleet('fleetName', event.target.value);
                        }}
                        error={this.props.errorsOnFleetSave.hasFleetNameError}
                        fullWidth
                    />
                    <TextField
                        sx={styles.formField}
                        variant='standard'
                        size='small'
                        helperText={this.props.datavanNameHelperText}
                        inputProps={{maxLength: 255}}
                        value={this.props.fleetToSave.datavan}
                        onChange={(event) => {
                            this.props.onChangeFleet('datavan', event.target.value);
                        }}
                        onBlur={(event) => {
                            this.props.onBlurDatavan(event.target.value, this.props.ownerId);
                        }}
                        error={this.props.errorsOnFleetSave.hasDatavanError || this.props.warningDatavanOnFleetSave}
                        fullWidth
                    />
                    <TextField
                        sx={styles.formField}
                        variant='standard'
                        size='small'
                        helperText="Region"
                        inputProps={{maxLength: 255}}
                        value={this.props.fleetToSave.region}
                        onChange={(event) => {
                            this.props.onChangeFleet('region', event.target.value);
                        }}
                        fullWidth
                    />
                    <Box>
                        <AutoCompleteMDT
                            sx={{...styles.formField, width: '200px'}}
                            options={divisionOptions}
                            value={this.props.fleetToSave.division || null}
                            noOptionsText={'No divisions found...'}
                            renderInput={(params) => <TextField {...params} variant="standard" helperText="Division"
                                                                error={this.props.errorsOnFleetSave.hasDivisionError}
                                                                inputProps={{ ...params.inputProps, readOnly: true }}/>}
                            onChange={(event, value, reason) => {
                                this.props.onChangeFleet('division', value.label);
                            }}
                            isOptionEqualToValue={(option, value) => option.label == value}
                        />
                    </Box>
                    <Box>
                        <AutoCompleteMDT
                            sx={{...styles.formField, width: '300px'}}
                            options={typeOptions}
                            value={typeOptions.find(option => option.value === this.props.fleetToSave.type)?.label || null}
                            noOptionsText={'No types found...'}
                            renderInput={(params) => <TextField {...params} variant="standard" helperText="Type"
                                                                error={this.props.errorsOnFleetSave.hasTypeError}
                                                                inputProps={{ ...params.inputProps, readOnly: true }}/>}
                            onChange={(event, value, reason) => {
                                this.props.onChangeFleet('type', value.value);
                            }}
                            isOptionEqualToValue={(option, value) => option.label == value}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button sx={dialogStyles.actionButton} variant={'contained'}
                            onClick={() => this.props.onCloseSaveFleetDialog()}>Cancel</Button>
                    <Button variant={'contained'} color='primary' onClick={() => this.props.onSaveFleet(this.props.fleetToSave)}
                            disabled={!_.isEmpty(Object.keys(this.props.errorsOnFleetSave))}>Save</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? ComponentTypes.FLEET_MANAGEMENT : props.stateKey,
            type: ComponentTypes.FLEET_MANAGEMENT,
        }
    }
};

const mapStateToProps = (state, props) => {
    const {stateDef} = props;
    const componentState = fleetMgmtState(state[stateDef.key]);
    const appState = applicationState(state);

    return {
        openSaveFleet: componentState.openSaveFleet,
        fleetToSave: componentState.fleetToSave,
        errorsOnFleetSave: componentState.errorsOnFleetSave,
        ownerId: _.isNil(appState.selectedOwner) ? appState.user.ownerId : appState.selectedOwner.value,
        datavanNameHelperText: componentState.datavanNameHelperText,
        warningDatavanOnFleetSave: componentState.warningDatavanOnFleetSave
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onCloseSaveFleetDialog: () => {
            dispatch(fleetMgmtActions.onCloseSaveFleetDialog(props.stateDef))
        },
        onSaveFleet: (fleet) => {
            dispatch(fleetMgmtActions.onSaveFleet(props.stateDef, fleet))
        },
        onChangeFleet: (property, value) => {
            dispatch(fleetMgmtActions.onChangeFleet(props.stateDef, property, value))
        },
        onBlurDatavan: (datavanName, ownerId) => {
            dispatch(fleetMgmtActions.onBlurDatavan(props.stateDef, datavanName, ownerId));
        }
    }
};

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(SaveFleetDialog))
/**
 * Note sure how/where we are going to keep/organize our user friendly error messages.
 * Keeping them all in one module for now.
 */
const errorMessages = {
    ERROR_RETRIEVING_TRUCKS: "Unable to retrieve truck list from server.",
    ERROR_EXPORTING: "An error occurred while exporting the data.",
    ERROR_RETRIEVING_USER_INFO: "Unable to retrieve user information from server.",
    ERROR_SIGNING_IN: "An error occurred while signing in.",
    ERROR_RETRIEVING_USERS: "Unable to retrieve user list from server.",
    ERROR_ADDING_USER: "An error occurred while adding the user.",
    ERROR_DELETING_USER: "An error occurred while deleting the user.",
    ERROR_UPDATING_USER: "An error occurred while updating the user.",
    ERROR_RETRIEVING_USER_PROFILE_SETTINGS: "An error occurred while retrieving user profile settings.",
    ERROR_SAVING_USER_PROFILE_SETTINGS: "An error occurred while saving user profile settings.",
    ERROR_RETRIEVING_EQUIPMENT_LIST: "Unable to retrieve equipment list from server.",
    ERROR_RETRIEVING_PUMP_LIST: "Unable to retrieve pump list from server.",
    ERROR_RETRIEVING_UNIT_HOURS_CARD_DATA: "Unable to retrieve Unit Hours card data.",
    ERROR_RETRIEVING_UNIT_STATUS_CARD_DATA: "Unable to retrieve Unit Status card data.",
    ERROR_RETRIEVING_CLIENT_STATUS_DATA: "Unable to retrieve Cloud Client status data.",
    ERROR_RETRIEVING_UNIT_ACTIVITY_CARD_DATA: "Unable to retrieve Unit Activity card data.",
    ERROR_RETRIEVING_UNIT_HISTORY_CARD_DATA: "Unable to retrieve Unit History card data.",
    ERROR_RETRIEVING_UNIT_OUTPUT_HISTORY_CARD_DATA: "Unable to retrieve Unit Output History card data.",
    ERROR_RETRIEVING_DATA_AGE: "Unable to load data age from server.",
    ERROR_RETRIEVING_FLEET_LIST: "Unable to retrieve fleet list from server.",
    ERROR_RETRIEVING_FLEET_ACTIVITY_CARD_DATA: "Unable to retrieve Fleet Activity card data.",
    ERROR_RETRIEVING_FLEET_PRC_HISTORY_CARD_DATA: "Unable to retrieve fleet PRC History card data.",
    ERROR_RETRIEVING_FLEET_ALARM_COUNT_CARD_DATA: "Unable to retrieve the Fleet Alarm card data",
    ERROR_RETRIEVING_UNIT_ALARM_COUNT_CARD_DATA: "Unable to retrieve Pump Alarm card data",
    ERROR_RETRIEVING_UNIT_FLEET_CARD_DATA: "Unable to retrieve Fleet card data.",
    ERROR_RETRIEVING_FLEET_PUMP_STATUS_CARD_DATA: "Unable to retrieve Fleet Pump Status card data.",
    ERROR_RETRIEVING_SYSTEM_SOFTWARE_LIST: "Unable to retrieve system software list from server.",
    ERROR_RETRIEVING_CLIENT_STATUS_DETAILS: "Unable to retrieve the details for this Cloud Client.",
    ERROR_RETRIEVING_EXPORT_DATA_DETAILS: "Unable to retrieve the export data details for this truck.",
    ERROR_RETRIEVING_FLEET_CLIENT_STATUS_CARD_DATA: "Unable to retrieve Client Status card data.",
    ERROR_RETRIEVING_COMPONENT_DISPLAY_DATA: "Unable to retrieve the component display data.",
    ERROR_RETRIEVING_COMPONENT_DETAILS: "Unable to retrieve the component details for this truck.",
    ERROR_SAVING_COMPONENT: "An error occurred while saving the component.",
    ERROR_DELETING_COMPONENT: "An error occurred while deleting the component.",
    ERROR_RETRIEVING_EXPECTED_LIFE_SETTINGS: "Unable to retrieve the expected life settings.",
    ERROR_SAVING_EXPECTED_LIFE_SETTINGS: "An error occurred while saving the expected life settings.",
    ERROR_RETRIEVING_COMPONENT_LIFE_CARD_DATA: "Unable to retrieve Component Life card data.",
    ERROR_RETRIEVING_COMPONENT_LIFE_CARD_TYPES: "Unable to retrieve component types for Component Life card.",
    ERROR_RETRIEVING_COMPONENT_LIFE_CURBSIDE_CARD_DATA: "Unable to retrieve Component Life Curbside card data.",
    ERROR_RETRIEVING_COMPONENT_LIFE_ROADSIDE_CARD_DATA: "Unable to retrieve Component Life Roadside card data.",
    ERROR_CALCULATING_ESTIMATED_PUMP_HOURS: "Unable to estimate pump hours.",
    ERROR_RETRIEVING_VIBRATION_HISTORY_CARD_DATA: "Unable to retrieve Vibration History card data.",
    ERROR_RETRIEVING_FLEET_MAP_SUMMARY: "Unable to retrieve Fleet Map summary.",
    ERROR_RETRIEVING_FLEET_MAP_DETAILS: "Unable to retrieve Fleet Map details.",
    ERROR_RETRIEVING_COMPONENT_HOURS: "Unable able to retrieve component hours from server.",
    ERROR_EXPORTING_CSV: "Unable able to export csv data.",
    ERROR_RETRIEVING_ACTIVE_ALARMS: "Unable able to retrieve active alarms from server.",
    ERROR_RETRIEVING_ALARM_HISTORY: "Unable able to retrieve alarm history from server.",
    ERROR_RETRIEVING_UNIT_ENGINE_CARD_DATA: "Unable to retrieve Unit Engine card data.",
    ERROR_RETRIEVING_UNIT_TRANSMISSION_CARD_DATA: "Unable to retrieve Unit Transmission card data.",
    ERROR_RETRIEVING_UNIT_POWER_END_CARD_DATA: "Unable to retrieve Unit Power End card data.",
    ERROR_RETRIEVING_UNIT_LOCKUP_CARD_DATA: "Unable to retrieve Unit Lockup card data.",
    ERROR_RETRIEVING_FLEET_OVERVIEW_DATA: "Unable to retrieve Fleet Overview data.",
    ERROR_RETRIEVING_FLEET_OVERVIEW_CARD_DATA: "Unable to retrieve Fleet Overview card data.",
    ERROR_RETRIEVING_FLEET_COMPONENT_CARD_DATA: "Unable to retrieve Fleet Component card data.",
    ERROR_RETRIEVING_FLEET_OPERATION_CARD_DATA: "Unable to retrieve Fleet Operation card data.",
    ERROR_RETRIEVING_FLEET_VIBRATION_CARD_DATA: "Unable to retrieve Fleet Vibration card data.",
    ERROR_RETRIEVING_DGB_DETAILS: "Unable able to retrieve DGB details from server.",
    ERROR_RETRIEVING_COIL_TUBING_UNIT_LIST: "Unable to retrieve coil tubing unit list from server.",
    ERROR_RETRIEVING_UNIT_COIL_DETAIL_CARD_DATA: "Unable to retrieve Unit Coil Detail card data.",
    ERROR_RETRIEVING_UNIT_COIL_CARD_DATA: "Unable to retrieve Unit Coil card data.",
    ERROR_RETRIEVING_UNIT_PRESSURE_HISTORY_ROADSIDE_CARD_DATA: "Unable to retrieve Unit Pressure History (Roadside) card data.",
    ERROR_RETRIEVING_UNIT_PRESSURE_HISTORY_CURBSIDE_CARD_DATA: "Unable to retrieve Unit Pressure History (Curbside) card data.",
    ERROR_RETRIEVING_UNIT_HOURS_CURBSIDE_CARD_DATA: "Unable to retrieve Unit Hours (Curbside) card data.",
    ERROR_RETRIEVING_UNIT_HOURS_ROADSIDE_CARD_DATA: "Unable to retrieve Unit Hours (Roadside) card data.",
    ERROR_RETRIEVING_UNIT_ACTIVITY_CURBSIDE_CARD_DATA: "Unable to retrieve Unit Activity (Curbside) card data.",
    ERROR_RETRIEVING_UNIT_ACTIVITY_ROADSIDE_CARD_DATA: "Unable to retrieve Unit Activity (Roadside) card data.",
    ERROR_RETRIEVING_DATA_EXPLORATION_CHART_DATA: "Error retrieving Data Exploration Chart data.",
    ERROR_RETRIEVING_FLEET_AUDIT_LOG_CARD_DATA: "Unable to retrieve the Fleet Audit Log card data",
    ERROR_RETRIEVING_UNIT_AUDIT_LOG_CARD_DATA: "Unable to retrieve Pump Audit Log card data",
    ERROR_SAVING_SENSOR_GROUP: "An error occurred while saving the Sensor Group.",
    ERROR_DELETING_SENSOR_GROUP: "An error occurred while deleting the Sensor Group.",
    ERROR_RETRIEVING_SENSOR_GROUPS: "An error occurred while retrieving Sensor Groups",
    ERROR_DUPLICATE_SENSOR_GROUP_NAME_WARNING: "Name already used. Saving will overwrite the existing Sensor Group.",
    ERROR_DOWNLOADING_VISIBLE_CHART_DATA: "Unable to download the visible chart data.",
    ERROR_VISIBLE_CHART_DATA_DOWNLOAD_PROCESSING: "An error occurred when processing the chart data for download.",
    ERROR_RETRIEVING_WARM_START_SETTINGS: "An error occurred when retrieving warmstart settings.",
    ERROR_RETRIEVING_WARM_START_TRUCK_SETTINGS: "An error occurred when retrieving warmstart truck settings.",
    ERROR_SAVING_WARM_START_SETTINGS: "An error occurred when saving warmstart settings.",
    ERROR_RETRIEVING_DATA_GRID_DATA: "Unable to retrieve Data Grid data.",

    ERROR_RETRIEVING_PROVIDER_SETTINGS: "An error occurred when retrieving provider settings.",
    ERROR_SAVING_PROVIDER_SETTINGS: "An error occurred when saving provider settings.",
    ERROR_DELETE_PROVIDER_SETTINGS: "An error occurred when deleting the provider settings.",
    ERROR_RETRIEVING_PROVIDER_DIALOG_DATA: "Unable to load context data for the provider Dialog.",
    ERROR_RETRIEVING_DEVICE_SETTINGS: "An error occurred when retrieving device settings.",
    ERROR_RETRIEVING_DEVICE_DIALOG_DATA: "Unable to load context data for the device Dialog.",
    ERROR_RETRIEVING_DEVICE_TRUCKS: "An error occurred when retrieving trucks for device.",
    ERROR_SAVING_DEVICE_SETTINGS: "An error occurred when saving device settings.",
    ERROR_SAVING_DEVICE_OWNER_SETTINGS: "An error occurred when saving device owner settings.",

    ERROR_RETRIEVING_SUBSCRIPTION_DIALOG_DATA: "Unable to retrieve events and providers data for the Notification Dialog.",
    ERROR_RETRIEVING_SUBSCRIPTIONS_DISPLAY_DATA: "Unable to retrieve notifications from server.",
    ERROR_SAVING_SUBSCRIPTION: "An error occurred when saving the notification.",
    ERROR_DELETE_SUBSCRIPTION: "An error occurred when deleting the notification.",
    ERROR_INPUT_FILTER_CONDITION: "An error occurred when input filter condition.",

    ERROR_EXPORTING_TRUCK_CONFIG: "An error occurred while exporting the truck config data.",

    ERROR_RETRIEVING_JOBS_OVERVIEW: "Unable to retrieve jobs from server for owner.",
    ERROR_RETRIEVING_JOB_OVERVIEW: "Unable to retrieve job from server.",
    ERROR_RETRIEVING_JOB_EVENT_LOGS: "Unable to retrieve job event logs from server.",
    ERROR_SAVING_JOB: "An error occurred when saving the job update.",
    ERROR_RETRIEVING_STAGE_DATA: "Unable to retrieve stages from server.",
    ERROR_RETRIEVING_STAGE_DEFINITION: "Unable to retrieve stage definition from server.",
    ERROR_SAVING_STAGE: "An error occurred when saving the stage update.",
    ERROR_ACCEPT_REJECT_STAGE: "The stage status has been changed by other users already. You cannot accept/reject it anymore.",
    ERROR_RETRIEVING_JOBS_HISTORY: "Unable to retrieve jobs from server.",
    ERROR_RETRIEVING_JOB_METADATA: "Unable to retrieve job metadata from server.",

    ERROR_SAVING_USER_LANDING_PAGE: "An error occurred when saving the landing page.",
    ERROR_GETTING_AVAILABLE_DISPLAYS: "Unable to retrieve available displays from server.",
    ERROR_RETRIEVING_USER_CONFIG_FOR_PAGE: "Unable to retrieve user configuration for page.",
    ERROR_SAVING_USER_CONFIG_FOR_PAGE: "An error occurred when saving user configuration for page.",

    ERROR_RETRIEVING_USER_CONFIG_FOR_DASHBOARD_LAYOUT: "Unable to retrieve user dashboard layout.",

    ERROR_RETRIEVING_CUSTOMERS_DISPLAY_DATA: "Unable to retrieve customers from server.",
    ERROR_SAVING_CUSTOMER: "An error occurred when saving the customer.",
    ERROR_DELETE_CUSTOMER: "An error occurred when deleting the customer.",
    ERROR_RETRIEVING_JOBS_CUSTOMER_DISPLAY: "Unable to retrieve jobs from server for customer.",
    ERROR_RETRIEVING_FLEETS: "An error occurred when retrieving fleets.",
    ERROR_SAVING_FLEET: "An error occurred when saving fleet.",
    WARNING_SAVING_FLEET_NEXTIER_WITHOUT_ASSETID_DISPLAY: "The datavan {datavan} does not have an asset id. The fleet {fleet} has been saved as Not Active.",
    WARNING_SAVING_FLEET_NEXTIER_WITHOUT_ASSETID_INLINE: "This datavan does not have an asset id. Continuing to save this fleet will result in the fleet being marked as Not Active.",
    ERROR_MOVING_FLEET: "An error occurred when moving fleet.",
    ERROR_RETRIEVING_OWNERS: "An error occurred when retrieving owners.",
    ERROR_RETRIEVING_DOWNLOADS_LIST: "Unable to retrieve available downloads from server.",

    ERROR_RETRIEVING_WELLS_DISPLAY_DATA: "Unable to retrieve wells from server.",
    ERROR_SAVING_WELL: "An error occurred when saving the well.",
    ERROR_DELETE_WELL: "An error occurred when deleting the well.",
    ERROR_RETRIEVING_ASSETID_FOR_DATAVAN: "An error occurred when retrieving assetId for datavan.",
    ERROR_SAVING_JOB_HISTORY: 'An error occurred when saving the job.',
    ERROR_SAVING_JOB_WELLS: 'An error occurred when saving the wells for the job.',
    ERROR_DELETING_JOB: 'An error occurred when deleting the job.',

    ERROR_SAVING_DASHBOARD: 'An error occurred when saving the dashboard.',
    ERROR_RESTORING_DEFAULTS: 'An error occurred when restoring default dashboards.',

    ERROR_LOADING_CARDS_LIST: 'An error occurred when loading the list of cards.',

    ERROR_USER_UNIT_CHART_DATA: 'An error occurred when getting User Unit Chart data.',

    ERROR_JOB_STAGE_CHART_DATA: 'An error occurred when getting Job Stage Chart data.',

    ERROR_RETRIEVING_AUDIT_LOGS_METADATA: 'Unable to retrieve metadata for Audit Logs.',
    ERROR_RETRIEVING_AUDIT_LOGS: 'Unable to retrieve Audit Logs.',
    
    ERROR_ASSET_TRACKING_GETTING_ASSETS: 'An error occurred when getting Asset Tracking assets.',

    ERROR_WEBSOCKET_SENDING_MESSAGE: 'An error occurred when sending a message to the WebSocket server.',
    
    ERROR_ASSET_TRACKING_GETTING_LOCATIONS: 'An error occurred when getting Asset Tracking locations.',
    ERROR_ASSET_TRACKING_SAVING_LOCATIONS: 'An error occurred when saving Asset Tracking locations.',
    ERROR_ASSET_TRACKING_DELETE_LOCATION: 'An error occurred when deleting an Asset Tracking location.',

    ERROR_LIVE_VIEW_QUERY_TRUCKS: 'An error occurred when querying trucks for Fleet Live View.',
    ERROR_LIVE_VIEW_QUERY_SENSOR_FOR_TRUCKS: 'An error occurred when querying sensor data for trucks in Fleet Live View.',
    ERROR_LIVE_VIEW_DOWNLOAD_DATA: 'An error occurred when generating the data to download for Fleet Live View.',
    ERROR_LIVE_VIEW_SAVING_CONFIGURATION: 'An error occurred when saving the Fleet Live View configuration.',
  
};

export default errorMessages;

import _ from 'lodash';

import ComponentTypes from '../../../components/componentTypes';

import { assetTypes } from '../../common/services/assetTypeService';

import unitUserChartActionTypes from './unitUserChartActionTypes';
import { unitUserChartState } from './unitUserChartSelectors';
import { updateDefn, normalizeSensorData } from './services/unitUserChartService';
import { getCardFromLayoutConfigViews } from '../../../components/common/layout/layoutHelper';
import { determineTruckFromDashboard } from './services/unitUserChartService';

const initialState = unitUserChartState();

const unitUserChartReducer = (state = initialState, action) => {
  switch (action.type) {
    case unitUserChartActionTypes.UNIT_USER_CHART_QUERY_STARTING:
      return { ...state, queryRunning: true };
    case unitUserChartActionTypes.UNIT_USER_CHART_QUERY_ERROR:
      return { ...state, queryRunning: false };
    case unitUserChartActionTypes.UNIT_USER_CHART_QUERY_SUCCESS:
      return onQuerySuccess(state, action);
    case unitUserChartActionTypes.UNIT_USER_CHART_QUERY_SENSORS_SUCCESS:
      return onQuerySensorSuccess(state, action);
    case unitUserChartActionTypes.UNIT_USER_CHART_CLEAR_DATA:
      return onClearData(state, action);

    case unitUserChartActionTypes.CHART_CONFIG_OPEN_SENSOR_SELECTOR:
      return updateConfigSensorSelectorState(state, true);
    case unitUserChartActionTypes.CHART_CONFIG_CLOSE_SENSOR_SELECTOR:
      return updateConfigSensorSelectorState(state, false);
    case unitUserChartActionTypes.CHART_CONFIG_SET_SELECTED_SENSORS:
      return onSelectedSensors(state, action.xAxisId, action.sensors);

    case unitUserChartActionTypes.CHART_CONFIG_UPDATE_DISPLAY_NAME:
      return onChangeDisplayName(state, action);
    case unitUserChartActionTypes.CHART_CONFIG_UPDATE_UOM:
      return onChangeUOM(state, action);
    case unitUserChartActionTypes.CHART_CONFIG_UPDATE_YAXIS:
      return onChangeYAxis(state, action);
    case unitUserChartActionTypes.CHART_CONFIG_UPDATE_LINE:
      return onChangeLine(state, action);
    case unitUserChartActionTypes.CHART_CONFIG_UPDATE_COLOR:
      return onConfigChangedColor(state, action);
    case unitUserChartActionTypes.CHART_CONFIG_SET_COLOR_PICKER_STATE:
      return onSetColorPickerState(state, action);

    case unitUserChartActionTypes.UNIT_USER_CHART_DEFINITION_SET_DEFAULT_TRUCK:
      return onSetDefinitionDefaultTruck(state, action);
    case unitUserChartActionTypes.UNIT_USER_CHART_DEFINITION_SET_START_TIME:
      return onSetDefinitionStartTime(state, action);
    case unitUserChartActionTypes.UNIT_USER_CHART_DEFINITION_SET_SENSORS:
      return onSetDefinitionSensors(state, action);
    
    case unitUserChartActionTypes.CHART_CONFIG_DISCARD_DEFINITION:
      return onDiscardDefinition(state, action);

    case unitUserChartActionTypes.UNIT_USER_CHART_SETUP_EDIT_MODE:
      return onSetupEditMode(state, action);
    default:
      return state;
  }
};

const onQuerySuccess = (state, action) => {

  // All the series have the same number of buckets so just use one to get the xValues
  const xValues = (_.isEmpty(action.queryResults.userUnitChart.series)) ? [] : [...action.queryResults.userUnitChart.series[0].timeStamp];
  const yValues = normalizeSensorData(xValues, action.queryResults.userUnitChart.series);

  let latest = {};
  // Update Latest values based on configured sensors and how those map to values returned
  // Sometimes there won't be data for a selected sensor
  _.map(state.definition.primary.sensors, (sensor) => {
    let latestValueForSeries = _.find(action.queryResults.userUnitChart.series, ['sensorSetId', sensor.sensorSetId])
    latest[sensor.sensorSetId] = (_.isNil(latestValueForSeries) || _.isNil(latestValueForSeries.latest)) ? '-' : parseFloat(latestValueForSeries.latest.toFixed(1)).toLocaleString('en');
  })

  return {
    ...state,
    queryRunning: false,
    xValues: xValues,
    yValues: yValues,
    latest: latest,
  }

}

const onQuerySensorSuccess = (state, action) => {
  const sensors = action.queryResults.sensorForAssets;
  let selectedSensors = _.cloneDeep(state.definition.primary.sensors);
  if (!_.isNil(sensors)) {
    const targetUoms = _.chain(sensors).keyBy('sensorSetId').mapValues('targetUoms').value();
    selectedSensors.forEach(sensor => {
      sensor.targetUoms = targetUoms[sensor.sensorSetId];
    });
  }
  return {
    ...state,
    queryRunning: false,
    definition: {
      ...state.definition,
      primary: {
        ...state.definition.primary,
        sensors: selectedSensors,
      }
    },
    allSensors: sensors ?? [],
  }
}

const onClearData = (state, action) => {
  return {
    ...state,
    ...initialState
  }
}

const updateConfigSensorSelectorState = (state, enabled) => {
  return {
    ...state,
    shouldOpenConfigSensorSelector: enabled
  }
};

const onSelectedSensors = (state, xAxisId, newSensors) => {
  if (_.isEqual(state.definition[xAxisId].sensors, newSensors)) {
    return state;
  }

  const definition = updateDefn(state.definition, newSensors, xAxisId, state.lineStylesList[0]);

  // Display an empty value for new sensors until the query returns an actual number
  let latest = _.cloneDeep(state.latest)
  _.forEach(newSensors, function(sensor) {
    if (_.isNil(latest[sensor.sensorSetId])) latest[sensor.sensorSetId] = '-'
  })
  
  return {
    ...state,
    definition: definition,
    latest: latest,
  }
};

const onChangeDisplayName = (state, action) => {
  let definition = _.cloneDeep(state.definition)
  _.find(definition.primary.sensors, function(sensor) { return sensor.alias === action.sensor; }).displayName = action.displayName;
  return {
    ...state,
    definition: definition,
  }
};

const onChangeUOM = (state, action) => {
  let definition = _.cloneDeep(state.definition)
  _.find(definition.primary.sensors, function(sensor) { return sensor.alias === action.sensor; }).uom = action.uom;
  return {
    ...state,
    definition: definition,
  }
};

const onChangeYAxis = (state, action) => {
  let definition = _.cloneDeep(state.definition)
  _.find(definition.primary.sensors, function(sensor) { return sensor.alias === action.sensor; }).yAxisId = action.yAxis;
  return {
    ...state,
    definition: definition,
  }
};

const onChangeLine = (state, action) => {
  let definition = _.cloneDeep(state.definition)
  _.find(definition.primary.sensors, function(sensor) { return sensor.alias === action.sensor; }).lineStyle = action.lineStyle;
  return {
    ...state,
    definition: definition,
  }
};

const onConfigChangedColor = (state, action) => {
  let definition = _.cloneDeep(state.definition)
  _.find(definition.primary.sensors, function(sensor) { return sensor.alias === action.sensor; }).color = action.color;
  return {
    ...state,
    definition: definition,
  }
};

const onSetColorPickerState = (state, action) => {
  return {
    ...state,
    colorPickerState: _.isNil(action.sensor) ? null : { sensor: action.sensor, origColor: action.origColor },
  }
};

const onSetDefinitionDefaultTruck = (state, action) => {
  let newDefinition = _.cloneDeep(state.definition);
  let truck = determineTruckFromDashboard(action.truck, action.dashboard);

  newDefinition.primary.defaultTruck = 
  {
    id: truck.id,
    pid: truck.truckPid,
    name: truck.name,
    unitType: truck.unitType
  };
  newDefinition.primary.trucks = Array(newDefinition.primary.sensors.length).fill(newDefinition.primary.defaultTruck);

  return {
    ...state,
    definition: newDefinition,
  }
}

const onSetDefinitionStartTime = (state, action) => {
  let newDefinition = _.cloneDeep(state.definition);
  newDefinition.primary.timeRange.startTime = action.startTime;
  newDefinition.primary.timeRange.duration = action.duration;
  return {
    ...state,
    definition: newDefinition,
  }
}

const onSetDefinitionSensors = (state, action) => {
  let newDefinition = _.cloneDeep(state.definition);
  newDefinition.primary.sensors = action.sensors;

  newDefinition.primary.trucks = Array(action.sensors.length).fill(newDefinition.primary.defaultTruck);

  return {
    ...state,
    definition: newDefinition,
  }
}

const onDiscardDefinition = (state, action) => {  
  const originalCard = getCardFromLayoutConfigViews(action.originalViews, action.view, action.cardKey);
  let newDefinition = _.cloneDeep(state.definition);
  // Original card may not exist because we just added it
  if (!_.isNil(originalCard)) {
    newDefinition.primary.sensors = originalCard.configuration.sensors || [];
  }
  return {
    ...state,
    definition: newDefinition
  }
}

const onSetupEditMode = (state, action) => {

  let clearedLatestValues = {};

  // Set all latest values to be no value
  _.forEach(_.keys(state.latest), (key) => {
    clearedLatestValues[key] = '-'
  }) 

  return {
    ...state,
    xValues: initialState.xValues,
    yValues: initialState.yValues,
    latest: clearedLatestValues
  }
}

export default unitUserChartReducer;
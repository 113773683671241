import _ from 'lodash';
import {fleetTypes} from "../../../common/services/fleetTypeService";
import {fleetDivisions} from "../../../common/services/fleetDivisionService";

const validateFleetChanges = (fleet) => {
    const fleetSaveErrors = {};
    if (_.isEmpty(fleet.fleetName)) {
        fleetSaveErrors.hasFleetNameError = true;
    }
    if (_.isEmpty(fleet.datavan)) {
        fleetSaveErrors.hasDatavanError = true;
    }

    if (_.isEmpty(fleet.type) || _.isNil(Object.values(fleetTypes).find( type => type === fleet.type))) {
        fleetSaveErrors.hasTypeError = true;
    }
    if (_.isEmpty(fleet.division) || _.isNil(Object.values(fleetDivisions).find(division => division === fleet.division))) {
        fleetSaveErrors.hasDivisionError = true;
    }
    return fleetSaveErrors;
};

const validateMoveFleetChanges = (fleet) => {
    const fleetMoveErrors = {};
    if (_.isEmpty(fleet.destinationFleetName)) {
        fleetMoveErrors.hasDestinationFleetNameError = true;
    }
    if (_.isEmpty(fleet.destinationDatavan)) {
        fleetMoveErrors.hasDestinationDatavanError = true;
    }
    if (_.isEmpty(fleet.destinationOwnerId)) {
        fleetMoveErrors.hasDestinationOwnerIdError = true;
    }
    if (_.isEmpty(fleet.type) || _.isNil(Object.values(fleetTypes).find( type => type === fleet.type))) {
        fleetMoveErrors.hasDestinationTypeError = true;
    }
    return fleetMoveErrors;
};

export {
    validateFleetChanges,
    validateMoveFleetChanges
}
export default {
    FLEET_MGMT_QUERY_FLEETS_STARTING: "FLEET_MGMT_QUERY_FLEETS_STARTING",
    FLEET_MGMT_QUERY_FLEETS_SUCCESS: "FLEET_MGMT_QUERY_FLEETS_SUCCESS",
    FLEET_MGMT_QUERY_FLEETS_ERROR: "FLEET_MGMT_QUERY_FLEETS_ERROR",
    FLEET_MGMT_QUERY_OWNERS_STARTING: "FLEET_MGMT_QUERY_OWNERS_STARTING",
    FLEET_MGMT_QUERY_OWNERS_SUCCESS: "FLEET_MGMT_QUERY_OWNERS_SUCCESS",
    FLEET_MGMT_QUERY_OWNERS_ERROR: "FLEET_MGMT_QUERY_OWNERS_ERROR",

    FLEET_MGMT_SAVE_FLEET_STARTING: "FLEET_MGMT_SAVE_FLEET_STARTING",
    FLEET_MGMT_SAVE_FLEET_SUCCESS: "FLEET_MGMT_SAVE_FLEET_SUCCESS",
    FLEET_MGMT_SAVE_FLEET_ERROR: "FLEET_MGMT_SAVE_FLEET_ERROR",
    FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_STARTING: "FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_STARTING",
    FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_SUCCESS: "FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_SUCCESS",
    FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_ERROR: "FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_ERROR",

    FLEET_MGMT_SET_ROWS_PER_PAGE: "FLEET_MGMT_SET_ROWS_PER_PAGE",

    FLEET_MGMT_OPEN_SAVE_FLEET_DIALOG: "FLEET_MGMT_OPEN_SAVE_FLEET_DIALOG",
    FLEET_MGMT_CLOSE_SAVE_FLEET_DIALOG: "FLEET_MGMT_CLOSE_SAVE_FLEET_DIALOG",
    FLEET_MGMT_OPEN_TOGGLE_FLEET_ACTIVE_DIALOG: "FLEET_MGMT_OPEN_TOGGLE_FLEET_ACTIVE_DIALOG",
    FLEET_MGMT_CLOSE_TOGGLE_FLEET_ACTIVE_DIALOG: "FLEET_MGMT_CLOSE_TOGGLE_FLEET_ACTIVE_DIALOG",
    FLEET_MGMT_OPEN_MOVE_FLEET_DIALOG: "FLEET_MGMT_OPEN_MOVE_FLEET_DIALOG",
    FLEET_MGMT_CLOSE_MOVE_FLEET_DIALOG: "FLEET_MGMT_CLOSE_MOVE_FLEET_DIALOG",
    FLEET_MGMT_OPEN_COPY_TO_CLIPBOARD_NOTIFICATION: "FLEET_MGMT_OPEN_COPY_TO_CLIPBOARD_NOTIFICATION",
    FLEET_MGMT_CLOSE_COPY_TO_CLIPBOARD_NOTIFICATION: "FLEET_MGMT_CLOSE_COPY_TO_CLIPBOARD_NOTIFICATION",
    FLEET_MGMT_CHANGE_FLEET_DATA: "FLEET_MGMT_CHANGE_FLEET_DATA",
    FLEET_MGMT_CHANGE_MOVE_FLEET_DATA: "FLEET_MGMT_CHANGE_MOVE_FLEET_DATA",

    FLEET_MGMT_MOVE_FLEET_STARTING: "FLEET_MGMT_MOVE_FLEET_STARTING",
    FLEET_MGMT_MOVE_FLEET_SUCCESS: "FLEET_MGMT_MOVE_FLEET_SUCCESS",
    FLEET_MGMT_MOVE_FLEET_ERROR: "FLEET_MGMT_MOVE_FLEET_ERROR"
};

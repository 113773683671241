import _ from 'lodash';

const deviceState = state => {

  return {

    device: {
      rowsPerPage: _.isNil(state) ? 10 : state.device.rowsPerPage,
      rowsPerPageOptions: [5,10,25],

      deviceCanSave: _.isNil(state) ? true : state.device.deviceCanSave,
      loadingDeviceSetting: _.isNil(state) ? false : state.device.loadingDeviceSetting,
      deviceSettings: _.isNil(state) ? [] : state.device.deviceSettings,

      showDeviceDialog: _.isNil(state) ? false : state.device.showDeviceDialog,
      trucks: _.isNil(state) ? [{value: null, label: null}] : state.device.trucks,
      queryRunning: _.isNil(state) ? false : state.device.queryRunning,
    }

  };

};

export {
  deviceState
};
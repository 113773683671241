import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {compose, withProps} from 'recompose';

import { Typography, Box, Skeleton, ButtonBase, Tooltip } from '@mui/material';
import ScreenshotIcon from '@mui/icons-material/Screenshot';

import ComponentTypes from '../../componentTypes';
import {MDTCard, mdtCardMapDispatchToProps, mdtCardPropTypes, mdtCardMapStateToProps} from "../mdtCard/mdtCard";
import getCardStyles from '../cardStyles';
import getTypographyStyles from '../../common/styles/typographyStyles';
import { sanitizeDefinition } from '../../../state/cards/unitUserChart/services/unitUserChartService';
import { getCardFromLayoutConfigViews } from '../../common/layout/layoutHelper';

import * as validationHelpers from '../../../helpers/validationHelper';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import cardChartOptions from '../../controls/charts/cardChart/cardChartOptions';

import CardChartAxisIndicator from '../../controls/charts/cardChart/cardChartAxisIndicator';
import { MdtYAxisPosition } from '../../../state/common/dataExplorationChart/mdtYAxisPosition';
import * as unitUserChartActions from '../../../state/cards/unitUserChart/unitUserChartActions';
import { unitUserChartState } from '../../../state/cards/unitUserChart/unitUserChartSelectors';
import takeScreenShot from '../../../helpers/screenshotHelper'
import * as appUserConfigActions from '../../../state/app/actions/appUserConfigActions';
import { appState as applicationState } from '../../../state/app/appSelectors';

const cardStyles = getCardStyles();
const typographyStyles = getTypographyStyles();

const styles = {
  ...cardStyles,
  cardContent: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100%',
  },
  noDataContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70%'
  },
  noDataLabel: {
    ...typographyStyles.noDataLabel
  },
  latestValues: {
    display: 'flex',
    alignItems: 'stretch',
    flexFlow: 'row nowrap',
    flexGrow: 1,
    justifyContent: 'center',
    height: '30%'
  },
  latestValuesBuffer: {
    display: 'flex',
    flex: '0 0 30px',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '0px',
  },
  latestValuesLabel: {
    transform: 'rotate(-90deg)',
    whiteSpace: 'nowrap',
  },
  latestValuesCenteredContent: {
    display: 'flex',
    width: '90%',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '90%',
    gap: '5px',
    marginLeft: '5px'
  },
  latestValue: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center'
  },
  seriesLabel: {
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'noWrap'
  },
  axisIndicator: {
    display: 'flex',
    flexFlow: 'row nowrap'
  },
  cardActionButton: {
    width: '24px',
    height: '24px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'grey.800',
    },
    '& svg': {
      fontSize: '20px',
      color: 'grey.500'
    }
  }
}

class UnitUserChart extends MDTCard {

  getName(){
    return 'USER CHART';
  }

  isContextReady(){
    return !_.isNil(this.props.context) && !_.isNil(this.props.context.truck) && !_.isNil(this.props.context.startTime && !_.isNil(this.props.context.endTime));
  }

  constructor(props) {
    super(props);
    //Set the card definition at app level if not existing
    if (_.isNil(this.props.card.configuration.sensors)) {
        this.props.initializeDefinition(this.props.dashboard, this.props.view, this.props.cardKey, sanitizeDefinition(this.props.definition));
    };
  }

  refresh() {
    if (!this.isContextReady()) {
      this.props.clearData();
    } else if (
      (validationHelpers.checkValueAgainstNotFunctions(this.props.context.startTime, [_.isNil, _.isNaN]) === true) &&
      (validationHelpers.checkValueAgainstNotFunctions(this.props.context.endTime, [_.isNil, _.isNaN]) === true)
    ){
      // Sometimes a Discard of Changes can cause the card to trigger a query before it had a chance to update its definition with the original
      // configuration
      // So let's only trigger a query if sensors in configuration match sensors in definition (expected == actual)
      const configuration = _.map(
        this.props.card.configuration.sensors || [],
        sensor => { return _.omit(sensor, ['targetUoms']) }
      );
      const definition = _.map(
        this.props.definition?.primary.sensors || [],
        sensor => { return _.omit(sensor, ['targetUoms']) }
      );
      if (_.isEqual(configuration, definition)) {
        this.props.queryData(this.props.context.truck, this.props.context.startTime, this.props.context.endTime, this.props.definition?.primary.sensors, this.props.cardQueryRunningCallback, this.props.dashboard);
      }
    }
  }

  componentDidMount() {
    // Initialize the truck, time range and sensors
    if (!_.isNil(this.props.context.truck)) {
      this.props.setChartDefinitionDefaultTruck(this.props.context.truck, this.props.dashboard);
    }
    if (!_.isNil(this.props.context.startTime) && !_.isNil(this.props.context.endTime)) {
      const duration = (this.props.context.endTime - this.props.context.startTime) / 60;
      this.props.setChartDefinitionStartTime(this.props.context.startTime, duration);
    }
    if (!_.isNil(this.props.card.configuration.sensors)) {
      this.props.setChartDefinitionSensors(this.props.card.configuration.sensors);
    }
    // Introduce a tiny delay before calling the super.componentDidMount
    // When switching views, it's possible the setChartDefinitionSensors does not complete its lifecycle before the call to 
    // super.componentDidMount happens so the card will mount and the refresh() call won't execute
    // This maybe an artifact of dev mode so this can be removed if proven so
    setTimeout(() => { super.componentDidMount()}, 25);
  }

  componentDidUpdate(prevProps) {
    // If Truck has changed, change the default truck on the definition
    if (!_.isEqual(prevProps.context.truck, this.props.context.truck)) {
      this.props.setChartDefinitionDefaultTruck(this.props.context.truck, this.props.dashboard);
    }
    // If Start Time or duration have changed, change the start time and duration on the definition
    if ((!_.isEqual(prevProps.context.startTime, this.props.context.startTime)) || (!_.isEqual(prevProps.context.endTime, this.props.context.endTime))) {
      const duration = (this.props.context.endTime - this.props.context.startTime) / 60;
      this.props.setChartDefinitionStartTime(this.props.context.startTime, duration);
    }
    // If sensors change in configuration, then update the definition
    // This helps when discarding changes and we restore the configuration object before the definition, since the comparison is done at the configuration level,
    // which exists only with the layout object
    if (!_.isNil(this.props.card.configuration.sensors) && (!_.isEqual(prevProps.card.configuration.sensors, this.props.card.configuration.sensors))) {
      this.props.setChartDefinitionSensors(this.props.card.configuration.sensors);
    }
    // Clear the x, y, and latest values if we are in edit mode
    // This lets the card be configured without any confusion as to the data that may already be showing
    if (this.isInEditMode() && (_.isNil(prevProps.editMode) || !prevProps.editMode)) {
      this.props.setupEditMode();
    }
    super.componentDidUpdate(prevProps);
  }

  getRenderedButtonsInHeader() {
    const noSensorsSelected = (_.isEmpty(this.props.definition.primary.sensors));
    return (
        !this.isInEditMode() &&
        <Tooltip disableInteractive title={"Take Screenshot"}><Box>
          <ButtonBase focusRipple
            sx={styles.cardActionButton}
            onClick={() => takeScreenShot(this.props.stateDef.key)}
            disabled={noSensorsSelected}
          >
            <ScreenshotIcon/>
          </ButtonBase>
        </Box></Tooltip>
    )
  }

  getRenderedContent() {

    let widthOfLatestValue = 100 / (_.isEmpty(this.props.definition.primary.sensors) ? 1 : this.props.definition.primary.sensors.length);
    // If we are in edit mode, we also want to see the chart because we want to see the effect of adding/removing sensors
    const hideChart = (_.isEmpty(this.props.xValues) || _.isEmpty(this.props.yValues)) && (!this.props.editMode) && (!this.props.queryRunning);
    const noSensorsSelected = (_.isEmpty(this.props.definition.primary.sensors));
    const chartOptions = cardChartOptions(this.props.definition, this.props.xValues, this.props.yValues);
    return (
      <Box id={"screenshot-area@" + this.props.stateDef.key} sx={{...styles.cardContent, backgroundColor: styles.cardContainer.backgroundColor}}>
        {hideChart ? 
        (
          <Box sx={styles.noDataContainer}>
            <Typography variant={'caption'} sx={styles.noDataLabel}>{noSensorsSelected ? 'Please Select Sensors to Chart' : 'No Data'}</Typography>
          </Box>
        ) : 
        (
          (
            this.props.queryRunning === true ?
            <Skeleton variant='rounded' sx={{ height: '100%', display: 'flex', flexFlow: 'row nowrap', margin: 1, backgroundColor: 'rgba(35,35,35,0.4)' }}/>
            : 
            <Box sx={{ height: '70%', display: 'flex', flexFlow: 'row nowrap' }}>
              <Box sx={{ ...styles.axisIndicator, marginLeft: 1 }}>
                <CardChartAxisIndicator yAxisId={MdtYAxisPosition.LeftOuter} definition={this.props.definition} />
              </Box>
              <Box sx={{...styles.axisIndicator, marginRight: '5px'}}>
                <CardChartAxisIndicator yAxisId={MdtYAxisPosition.LeftInner} definition={this.props.definition} />
              </Box>
              <Box style={{ height: 'calc(100%)', display: 'flex', flexFlow: 'row nowrap', flexGrow: 1 }}>
                <HighchartsReact immutable containerProps={{ style: { width: "100%", height: '100%' } }} highcharts={Highcharts} options={chartOptions}/>
              </Box>
              <Box sx={{...styles.axisIndicator, marginLeft: '5px'}}>
                <CardChartAxisIndicator yAxisId={MdtYAxisPosition.RightInner} definition={this.props.definition} />
              </Box>
              <Box sx={{ ...styles.axisIndicator, marginRight: 1 }}>
                <CardChartAxisIndicator yAxisId={MdtYAxisPosition.RightOuter} definition={this.props.definition} />
              </Box>
            </Box>
          )
        )}
        
        <Box sx={styles.latestValues}>
          <Box sx={styles.latestValuesBuffer}>
            <Typography sx={styles.latestValuesLabel} variant={'subtitle1'}>Latest</Typography>
          </Box>
          <Box sx={styles.latestValuesCenteredContent}>
            { !hideChart &&
              _.map(_.sortBy(this.props.definition.primary.sensors, [(s) => { return s.yAxisId }]), (sensor, index) => {
                if (!_.isNil(sensor)) {
                  return (
                    <Box key={index} sx={{...styles.latestValue, width: widthOfLatestValue + '%'}}>
                      <Typography variant={'h4'} sx={{...styles.seriesLabel, color: sensor.color}}>{this.props.latest[sensor.sensorSetId]}</Typography>
                      <Tooltip title={sensor.displayName + ' (' + sensor.uom + ')'}>
                        <Typography variant={'subtitle1'} sx={{...styles.seriesLabel, color: sensor.color}}>{sensor.displayName}{' (' + sensor.uom + ')'}</Typography>
                      </Tooltip>
                    </Box>  
                  )
                }
              })
            }
          </Box>
          <Box sx={styles.latestValuesBuffer} />
        </Box>
      </Box>
    )
  }
}

UnitUserChart.propTypes = mdtCardPropTypes;

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.UNIT_USER_CHART : props.stateKey,
      type: ComponentTypes.UNIT_USER_CHART,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = unitUserChartState(state[stateDef.key]);
  let appState = applicationState(state);
  return {
    ...mdtCardMapStateToProps(state, props),
    queryRunning: componentState.queryRunning,
    definition: componentState.definition,
    xValues: componentState.xValues,
    yValues: componentState.yValues,
    shouldOpenConfigSensorSelector: componentState.shouldOpenConfigSensorSelector,
    latest: componentState.latest,
    card: getCardFromLayoutConfigViews(appState.user.dashboards[props.dashboard].views, props.view, props.cardKey),
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...mdtCardMapDispatchToProps(dispatch, props),
    clearData: () => { dispatch(unitUserChartActions.clearData(props.stateDef)); },
    queryData: (truck, startTime, endTime, sensors, cardQueryRunningCallback, dashboard) => { dispatch(unitUserChartActions.queryData(props.stateDef, truck, startTime, endTime, sensors, cardQueryRunningCallback, dashboard)); },
    setChartDefinitionDefaultTruck: (truck, dashboard) => { dispatch(unitUserChartActions.setDefinitionDefaultTruck(props.stateDef, truck, dashboard)); },
    setChartDefinitionStartTime: (startTime, duration) => { dispatch(unitUserChartActions.setDefinitionStartTime(props.stateDef, startTime, duration)); },
    setChartDefinitionSensors: (sensors) => { dispatch(unitUserChartActions.setDefinitionSensors(props.stateDef, sensors)); },
    setupEditMode: () => { dispatch(unitUserChartActions.setupEditMode(props.stateDef)); },
    initializeDefinition: (dashboard, view, cardKey, definition) => { dispatch(appUserConfigActions.onChangeConfig(dashboard, view, cardKey, definition)); },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(UnitUserChart));

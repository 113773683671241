import defaultActionFactory from "../../common/factories/defaultActionFactory";
import fleetMgmtActionTypes from "./fleetMgmtActionTypes";
import {fetchAssetInfoForDatavan, fetchFleets, fetchMoveFleet, fetchOwners, fetchSaveFleet} from "./fleetMgmtQueries";
import errorMessages from "../../common/errorMessages";
import queryActionFactory from "../../common/factories/queryActionFactory";
import { handleError } from "../../app/actions/appErrorActions";
import _ from "lodash";
import OWNERS from "../../../components/common/owners";

const onOpenSaveFleetDialog = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_OPEN_SAVE_FLEET_DIALOG, "stateDef", "fleetToSave");
const onCloseSaveFleetDialog = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_CLOSE_SAVE_FLEET_DIALOG, "stateDef");
const onOpenToggleFleetConfirmationDialog = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_OPEN_TOGGLE_FLEET_ACTIVE_DIALOG, "stateDef", "fleet");
const onCloseToggleFleetConfirmationDialog = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_CLOSE_TOGGLE_FLEET_ACTIVE_DIALOG, "stateDef");
const onOpenMoveFleetDialog = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_OPEN_MOVE_FLEET_DIALOG, "stateDef", "fleet");
const onCloseMoveFleetDialog = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_CLOSE_MOVE_FLEET_DIALOG, "stateDef");

const queryFleets = queryActionFactory(
  fleetMgmtActionTypes.FLEET_MGMT_QUERY_FLEETS_STARTING,
  fleetMgmtActionTypes.FLEET_MGMT_QUERY_FLEETS_SUCCESS,
  fleetMgmtActionTypes.FLEET_MGMT_QUERY_FLEETS_ERROR,
  errorMessages.ERROR_RETRIEVING_FLEETS,
  fetchFleets
);

const queryOwners = queryActionFactory(
    fleetMgmtActionTypes.FLEET_MGMT_QUERY_OWNERS_STARTING,
    fleetMgmtActionTypes.FLEET_MGMT_QUERY_OWNERS_SUCCESS,
    fleetMgmtActionTypes.FLEET_MGMT_QUERY_OWNERS_ERROR,
    errorMessages.ERROR_RETRIEVING_OWNERS,
    fetchOwners
);
const onChangeFleet = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_CHANGE_FLEET_DATA, "stateDef", "property", "value");
const onChangeMoveFleet = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_CHANGE_MOVE_FLEET_DATA, "stateDef", "property", "value");

const validateDatavanAssetIdStarting = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_STARTING, "stateDef");
const validateDatavanAssetIdSuccess = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_SUCCESS, "stateDef", "assetInfo");
const validateDatavanAssetIdError = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_VALIDATE_DATAVAN_ASSETID_ERROR, "stateDef");

const onBlurDatavan = (stateDef, datavanName, ownerId) => {
    return async (dispatch, getState) => {
        if(ownerId === OWNERS.NEXTIER && !_.isEmpty(datavanName)) {
            let queryResult;
            try {
                await dispatch(validateDatavanAssetIdStarting(stateDef));
                queryResult = await fetchAssetInfoForDatavan(datavanName);
                await dispatch(validateDatavanAssetIdSuccess(stateDef, queryResult.assetInfoForTruck));
            } catch (e) {
                await dispatch(validateDatavanAssetIdError(stateDef));
                return dispatch(handleError(errorMessages.ERROR_RETRIEVING_ASSETID_FOR_DATAVAN, e.message));
            }
        }
    };
};

const saveFleetDataStarting = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_SAVE_FLEET_STARTING, "stateDef");
const saveFleetDataSuccess = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_SAVE_FLEET_SUCCESS, "stateDef");
const saveFleetDataError = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_SAVE_FLEET_ERROR, "stateDef");

const onSaveFleet = (stateDef, fleet) => {
  return async (dispatch, getState) => {
    try {
      await dispatch(saveFleetDataStarting(stateDef));
      const savedFleet = await fetchSaveFleet(fleet);
      await dispatch(saveFleetDataSuccess(stateDef));
      await dispatch(queryFleets(stateDef));

      //We have a case of set a active fleet inactive when saving a nextier fleet without a datavan with assetId assigned
      //So we check the active status and assume if the returned fleet active state is different from the fleet requested, we will send a notification message
      if(!_.isNil(savedFleet.saveFleet) && savedFleet.saveFleet.active !== fleet.active) {
          const errorMessage = errorMessages.WARNING_SAVING_FLEET_NEXTIER_WITHOUT_ASSETID_DISPLAY.replaceAll("{fleet}", savedFleet.saveFleet.fleetName)
                                                                                                 .replaceAll("{datavan}", savedFleet.saveFleet.datavan);
          await dispatch(handleError(errorMessage));
      }
    } catch (e) {
      await dispatch(saveFleetDataError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_SAVING_FLEET, e.message));
    }
  };
};

const moveFleetDataStarting = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_MOVE_FLEET_STARTING, "stateDef");
const moveFleetDataSuccess = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_MOVE_FLEET_SUCCESS, "stateDef");
const moveFleetDataError = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_MOVE_FLEET_ERROR, "stateDef");

const onMoveFleet = (stateDef, fleetToMove) => {
  return async (dispatch, getState) => {
    try {
      await dispatch(moveFleetDataStarting(stateDef));
      await fetchMoveFleet(fleetToMove);
      await dispatch(moveFleetDataSuccess(stateDef));
      await dispatch(queryFleets(stateDef));
    } catch (e) {
      await dispatch(moveFleetDataError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_MOVING_FLEET, e.message));
    }
  };
};

const onOpenCopyToClipboardNotification = (stateDef) => {
    return async (dispatch, getState) => {
        return await dispatch(handleError("Copied to clipboard"));
    };
};

//function for setting the rows per page in the table
const setRowsPerPage = defaultActionFactory(fleetMgmtActionTypes.FLEET_MGMT_SET_ROWS_PER_PAGE, "stateDef", "rowSize");

export {
    queryFleets,
    setRowsPerPage,
    onOpenSaveFleetDialog,
    onCloseSaveFleetDialog,
    onChangeFleet,
    onChangeMoveFleet,
    onSaveFleet,
    onOpenToggleFleetConfirmationDialog,
    onCloseToggleFleetConfirmationDialog,
    onOpenMoveFleetDialog,
    onCloseMoveFleetDialog,
    onMoveFleet,
    queryOwners,
    onOpenCopyToClipboardNotification,
    onBlurDatavan
}

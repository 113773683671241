import {performDisplayFetch} from '../../common/fetch';
import * as fleetMgmtSimulation from './fleetMgmtSimulation';

import useSimulationData from "../../../helpers/simulationHelper";

const fetchFleets = () => {
    if (useSimulationData('fleetManagement')) {
        return fleetMgmtSimulation.fetchFleets();
    }

    return performDisplayFetch({
        'query': 'query fleetManagement { fleetManagement { id fleetIdentifier fleetName datavan active region division communicationStatus type } }'
    });
};

const fetchOwners = () => {
    if (useSimulationData('fleetManagement')) {
        return fleetMgmtSimulation.fetchOwners();
    }

    return performDisplayFetch({
        'query': 'query ownersWithApiKey { ownersWithApiKey{ id ownerName ownerApiKey } }'
    });
};

const fetchSaveFleet = (fleet) => {
    if (useSimulationData('fleetManagement')) {
        return fleetMgmtSimulation.fetchSaveFleet();
    }
    return performDisplayFetch({
        'query': 'mutation saveFleet($input:FleetInput!) {  saveFleet(input: $input) { id fleetIdentifier fleetName datavan active region division communicationStatus type } }',
        'variables': {
            'input': {
                'id': fleet.id,
                'fleetName': fleet.fleetName,
                'datavan': fleet.datavan,
                'region': fleet.region,
                'division': fleet.division,
                'type': fleet.type,
                'active': fleet.active
            }
        }
    });
};

const fetchMoveFleet = (fleet) => {
    return performDisplayFetch({
        'query': 'mutation moveFleet($input:MoveFleetInput!) {  moveFleet(input: $input) { success } }',
        'variables': {
            'input': {
                'srcFleetId': fleet.id,
                'destOwnerId': fleet.destinationOwnerId.id,
                'destFleetName': fleet.destinationFleetName,
                'destDatavan': fleet.destinationDatavan,
                'destType': fleet.type
            }
        }
    });
};

const fetchAssetInfoForDatavan = (datavan) => {
    if (useSimulationData('fleetManagement')) {
        return fleetMgmtSimulation.fetchAssetInfoForTruck(datavan);
    }
    return performDisplayFetch({
        'query': 'query AssetInfoForTruck($truckName:String!){  assetInfoForTruck(truckName: $truckName) {  assetId assetCategory  }}',
        'variables': {
            'truckName': datavan
        }
    });
}

export {
    fetchFleets,
    fetchOwners,
    fetchSaveFleet,
    fetchMoveFleet,
    fetchAssetInfoForDatavan
};
import _ from 'lodash';

const defnEmpty = () => {
  return {
    // the chart definition can be shared; a subset of the definition
    // should be used as input to the dataExploration component
    name: "",
    author: "",
    type: 'template',

    // first x-Axis id
    primary: {

      // time range
      timeRange: {
        // epoch seconds or null: indicating last N minutes from now
        startTime: null,
        // duration in minutes
        duration: 1440
      },

      // default truck should be used for all new sensors (object must include pid and name)
      defaultTruck: null,

      // trucks, one per sensor
      trucks: [],

      // sensor definitions (from sensor selector) and configuration (objects must include sensorSetId, alias, uom, yAxisId, color, isVisible)
      // Note that yAxisId is 0, 1, 2, 3 for outer left, inner left, inner
      // right, and outer right respectively
      sensors: [],
    },
  };
};

/**
 * Define some available line styles based on the api given by SciCharts
 * https://www.scichart.com/documentation/js/current/webframe.html#Series%20Styling%20-%20Dash%20Line%20Patterns.html
 * 
 * Each value contains an array of two values: 
 * 1. Length of a stroke, in pixels
 * 2. Gap between each stroke, in pixels
 */
const lineStyles = [
  {
    value: [10, 0],
    id: 109
  },
  {
    value: [20, 5],
    id: 110
  },
  {
    value: [4, 4],
    id: 112
  },
  {
    value: [10, 20],
    id: 113
  }
]

const unitUserChartState = (state) => {
  return {
    queryRunning : _.isNil(state) ? false : state.queryRunning,
    definition: _.isNil(state) ? defnEmpty() : state.definition,
    xValues: _.isNil(state) ? [] : state.xValues,
    yValues: _.isNil(state) ? [] : state.yValues,
    latest: _.isNil(state) ? [] : state.latest,

    shouldOpenConfigSensorSelector: _.isNil(state) ? false : state.shouldOpenConfigSensorSelector,
    colorPickerState: _.isNil(state) ? null : state.colorPickerState,
    lineStylesList: _.isNil(state) ? lineStyles : state.lineStylesList,
  };
};

export {
  unitUserChartState
};

import _ from "lodash";
import React, {Component} from 'react';
import {withProps} from "recompose";
import {connect} from "react-redux";
import { compose } from 'recompose';
import { Typography, Box, Paper, Tabs, Tab } from '@mui/material';
import Progress from '../../controls/progress';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import ExpectedLifeSettings from './forms/expectedLifeSettings';
import WarmStartSettings from './forms/warmStartSettings';
import ProviderSettings from './forms/providerSettings';

import ComponentTypes from '../../componentTypes';
import * as settingsActions from '../../../state/displays/settings/actions/settingsActions';
import { settingsState } from '../../../state/displays/settings/selectors/settingsSelectors';

import getDetailsPageStyles from '../../common/styles/detailsPageStyles';
import { appState as applicationState } from '../../../state/app/appSelectors';
import DeviceSettings from "./forms/deviceSettings";

const detailsPageStyles = getDetailsPageStyles();

const styles = {
  ...detailsPageStyles,
  pageTitle: {
    alignSelf: 'center',
    paddingTop: 2,
    paddingBottom: 2,
  },
};

class Settings extends Component {
  
  render() {

    return (
      <Box sx={styles.detailsPage}>
        <Box sx={styles.detailsPageContent}>
          <Box sx={styles.detailsPageHeader}>
            <Typography variant={'h6'} sx={styles.pageTitle}>Settings</Typography>
          </Box>
          <Paper>
            <Tabs
              value={this.props.formIndex}
              onChange={(event, value) => this.props.setSelectedFormIndex(value)}
              centered={true}
              indicatorColor='primary'
              textColor='primary'
            >
              {/* The order of the tabs should not be changed to avoid formIndex mismatch for display when showPreReleaseContent selected/deselected */}
              <Tab icon={<ScheduleIcon />} label='Expected Life'/>
              <Tab icon={<AttachMoneyIcon />} label='Warm Start'/>
              { this.props.isUserAdmin
              && <Tab icon={<AppSettingsAltIcon />} label='Providers'/>}
              { this.props.showPreReleaseContent &&
              <Tab icon={<ShareLocationIcon />} label='Devices'/>}

            </Tabs>
            { this.props.formIndex === 0 && <ExpectedLifeSettings/> }
            { this.props.formIndex === 1 && <WarmStartSettings/> }
            { this.props.formIndex === 2 && <ProviderSettings/>}
            { this.props.formIndex === 3 && <DeviceSettings/>}


          </Paper>
        </Box>
        <Progress open={this.props.queryRunning}/>
      </Box>
    );
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.SETTINGS : props.stateKey,
      type: ComponentTypes.SETTINGS,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let appState = applicationState(state);
  let componentState = settingsState(state[stateDef.key]);
  return {
    queryRunning: componentState.queryRunning,
    formIndex: componentState.formIndex,
    isUserAdmin: appState.user.isUserAdmin,
    showPreReleaseContent: appState.user.showPreReleaseContent,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setSelectedFormIndex: (index) => { dispatch(settingsActions.setSelectedFormIndex(props.stateDef, index)) },
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(Settings));
import _ from "lodash";
import React, {Component} from "react";
import {compose, withProps} from "recompose";
import {connect} from "react-redux";

import {Box, Button, IconButton, Snackbar, Switch, Tooltip, Typography} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import EditIcon from "@mui/icons-material/Edit";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Progress from "../../controls/progress";

import * as fleetMgmtActions from '../../../state/displays/fleetManagement/fleetMgmtActions';
import ComponentTypes from "../../componentTypes";
import {fleetMgmtState} from '../../../state/displays/fleetManagement/fleetMgmtSelectors';
import {trackPage} from '../../../helpers/googleAnalyticsHelper';
import {appState as applicationState} from "../../../state/app/appSelectors";
import getDetailsPageStyles from "../../common/styles/detailsPageStyles";
import MDTDataGrid from "../../common/table/MDTDataGrid";
import {GridActionsCellItem, gridClasses} from "@mui/x-data-grid-pro";
import EditFleetDialog from "./saveFleetDialog";
import FleetToggleConfirmationDialog from "./fleetToggleConfirmationDialog";
import MoveFleetDialog from "./moveFleetDialog";
import * as statusIconHelpers from "../../common/statusIconHelper";
import {getFleetTypeNameByType} from "../../../state/common/services/fleetTypeService";

const detailsPageStyles = getDetailsPageStyles();
const statusIconStyles = statusIconHelpers.getStatusIconStyles();
const styles = {
    ...detailsPageStyles,
    exportAnchor: {
        visibility: "hidden",
    },
    detailsPageContent: {
        ...detailsPageStyles.detailsPageContent,
        width: "98vw",
    },
    detailsPageHeaderActions: {
        ...detailsPageStyles.detailsPageHeaderActions,
        justifyContent: "flex-start",
    },
    mdtDataGridContainer: {
        '& .fleetIdentifierCell': {
            "&:hover button": {
                display: "inline",
            }
        },
        "& .MuiDataGrid-actionsCell": {
            visibility: "hidden",
        },
        [`& .${gridClasses.row}:hover`]: {
            ".MuiDataGrid-actionsCell": {
                visibility: "visible",
            },
        },
    },
    pageTitleStyles: {
        display: "flex",
        alignItems: "center",
        width: "180px",
    },
};

class FleetMgmtDisplay extends Component {
    constructor(props) {
        super(props);
        // Generate the columns data for the data table.
        this.columns = this.generateTableColumns();
    }

    componentDidMount() {
        // Track GA View
        trackPage(ComponentTypes.FLEET_MANAGEMENT, this.props.user);
        // Load the data from the service layer.
        this.props.queryFleets();
        this.props.queryOwners();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedOwner !== this.props.selectedOwner) {
            // Query the data again if the owner changes.
            this.props.queryFleets();
        }
    }


    generateTableColumns() {
        let columns = [];
        columns.push({
            field: 'fleetName',
            headerName: 'Fleet Name',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            editable: false,
            hideable: false,
            pinnable: false
        });
        columns.push({
            field: 'datavan',
            headerName: 'Datavan',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            editable: false
        });
        columns.push({
            field: 'fleetIdentifier',
            headerName: 'Fleet Identifier',
            headerAlign: 'center',
            align: 'center',
            flex: 1.5,
            editable: false,
            cellClassName: 'fleetIdentifierCell',
            renderCell: (params) => {
                return (
                    <Box>
                        <span> {params.value} </span>
                        <Tooltip disableInteractive title="Copy to Clipboard" sx={{
                            display: "none"
                        }}>
                            <IconButton
                                size="small"
                                onClick={async () => {
                                    await navigator.clipboard.writeText(params.value);
                                    this.props.onOpenCopyToClipboardNotification();
                                }}
                            >
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                );
            }
        });
        columns.push({
            field: 'division',
            headerName: 'Division',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            editable: false
        });
        columns.push({
            field: 'type',
            headerName: 'Type',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            editable: false,
            renderCell: (params) => {
                return (
                    <Box>
                        { getFleetTypeNameByType(params.value)}
                    </Box>
                );
            }
        });
        columns.push({
            field: "active",
            headerName: "Active",
            headerAlign: "center",
            align: "center",
            editable: false,
            hideable: false,
            pinnable: false,
            renderCell: (params) => {
                return (
                    <Box>
                        <Switch checked={params.value} onChange={(event) => {
                            this.props.onToggleFleetActiveFlag(params.row);
                        }}/>
                    </Box>
                );
            }
        });
        columns.push({
            field: 'communicationStatus',
            headerName: 'Communication Status',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            editable: false,
            renderCell: (params) => {
                return (
                    <Box>
                        { statusIconHelpers.getClientStatusIcon(params.value, statusIconStyles) }
                    </Box>
                );
            }
        });
        columns.push({
            field: 'Actions',
            align: 'center',
            resizable: false,
            hideable: false,
            disableReorder: true,
            type: 'actions',
            getActions: (params) => {
                const actions = [];
                const isNotOnline = params.row.communicationStatus !== "Online" ;
                actions.push(<GridActionsCellItem icon={<EditIcon fontSize={'small'}/>}
                                                  onClick={() => this.props.openSaveFleetDialog(params.row)}
                                                  label="Edit Fleet" showInMenu/>);
                actions.push(<GridActionsCellItem disabled={!isNotOnline} icon={<MoveDownIcon fontSize={'small'}/>}
                                                  onClick={() => this.props.onOpenMoveFleetDialog(params.row)}
                                                  label="Move Fleet" showInMenu/>);
                return actions;
            }
        });
        return columns;
    }

    render() {
        return (
            <Box sx={styles.detailsPage}>
                <Box sx={styles.detailsPageContent}>
                    <Box sx={styles.detailsPageHeader}>
                        <Box sx={styles.pageTitleStyles}>
                            <Typography variant={"h6"}>Fleet Management</Typography>
                        </Box>
                        <Box sx={styles.detailsPageHeaderActions}>
                            <IconButton onClick={() => this.props.queryFleets()} size="large">
                                <RefreshIcon/>
                            </IconButton>
                            <Button onClick={() => this.props.openSaveFleetDialog({ active: true })} color={"inherit"}>
                                <PlaylistAddIcon sx={{marginRight: '5px'}}/>
                                 ADD FLEET
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={styles.mdtDataGridContainer}>
                        <MDTDataGrid
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'fleetName', sort: 'asc' }],
                                },
                            }}
                            columns={this.columns}
                            rows={this.props.fleets}
                            disableSelectionOnClick
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    printOptions: {
                                        disableToolbarButton: true,
                                    },
                                    csvOptions: {
                                        fileName: "fleets",
                                    },
                                },
                            }}
                            stateDef={this.props.stateDef}
                            rowsPerPage={this.props.rowsPerPage}
                            setRowsPerPage={this.props.setRowsPerPage}
                            rowsPerPageOptions={this.props.rowsPerPageOptions}
                        />
                    </Box>
                </Box>
                <Progress open={this.props.queryRunning}/>
                <EditFleetDialog/>
                <FleetToggleConfirmationDialog/>
                <MoveFleetDialog/>
            </Box>
        );
    }
}

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? ComponentTypes.FLEET_MANAGEMENT : props.stateKey,
            type: ComponentTypes.FLEET_MANAGEMENT,
        },
    };
};

const mapStateToProps = (state, props) => {
    const {stateDef} = props;
    const componentState = fleetMgmtState(state[stateDef.key]);
    const appState = applicationState(state);

    return {
        user: appState.user,
        selectedOwner: appState.selectedOwner,
        queryRunning: componentState.queryRunning,
        fleets: componentState.fleets,
        rowsPerPage: componentState.rowsPerPage,
        rowsPerPageOptions: componentState.rowsPerPageOptions,
        openCopyToClipBoardNotification: componentState.openCopyToClipBoardNotification
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        queryFleets: () => dispatch(fleetMgmtActions.queryFleets(props.stateDef)),
        queryOwners: () => dispatch(fleetMgmtActions.queryOwners(props.stateDef)),
        setRowsPerPage: (rowSize) => dispatch(fleetMgmtActions.setRowsPerPage(props.stateDef, rowSize)),
        openSaveFleetDialog: (fleetToSave) => dispatch(fleetMgmtActions.onOpenSaveFleetDialog(props.stateDef, fleetToSave)),
        onToggleFleetActiveFlag: (fleet) => dispatch(fleetMgmtActions.onOpenToggleFleetConfirmationDialog(props.stateDef, fleet)),
        onOpenMoveFleetDialog: (fleet) => dispatch(fleetMgmtActions.onOpenMoveFleetDialog(props.stateDef, fleet)),
        onOpenCopyToClipboardNotification: () => dispatch(fleetMgmtActions.onOpenCopyToClipboardNotification(props.stateDef))
    }
};

export default compose(withProps(stateDefinition))(connect(mapStateToProps, mapDispatchToProps)(FleetMgmtDisplay));

import _ from 'lodash';
import { getUniqueSeriesColor } from '../../../common/dataExplorationChart/services/dataExplorationChartService';
import ComponentTypes from '../../../../components/componentTypes';
import { assetTypes } from '../../../common/services/assetTypeService';

/**
 * Fill series data with NaN values to start.
 * Then fill in data when there is a value. Ignore nulls.
 *
 * @param seriesValues Array of series data values.
 * @param xValues Timestamps for each data value
 */
const fillSeriesWithData = (xValues, seriesValues) => {
  let values = Array(xValues.length).fill(NaN);
  _.forEach(xValues, (t, i) => {
    let value = seriesValues[i];
    if (!_.isNil(value)) {
      values[i] = value;
    }
  });
  return values;
};

/**
 * Normalize and prepare raw sensor data.
 * @param seriesData Data returned from the query
 * @param xValues Timestamps for each data value
 * @return {unknown[]} Normalized sensor data.
 */
const normalizeSensorData = (xValues, seriesData) => {
  let sensorData = {};

  _.map(seriesData, (series) => {
    sensorData[series.sensorSetId] = fillSeriesWithData(xValues, series.value);
  });

  return _.isNil(sensorData) ? [] : sensorData;
};

/**
 * Update definition when sensor selection is modified.
 * @param oldDefn Definition currently stored in state
 * @param newSensors New list of sensors (some may be repeated)
 * @param xAxisId Primary or Secondary
 * @param defaultLineStyle From state, automatically provide user with this style
 * @return definition Updated definition with appropriate sensors added.
 */

const updateDefn = (oldDefn, newSensors, xAxisId, defaultLineStyle) => {
    let definition = _.cloneDeep(oldDefn);

    // Find common sensors, don't modify these 
    let updatedSensorList = _.intersectionBy(oldDefn[xAxisId].sensors, newSensors, 'alias');
    let aliases = []
    updatedSensorList.forEach(s => {
        const newUOM = _.find(newSensors, function(sensor) { return sensor?.alias === s?.alias; }).uom;
        s.uom = newUOM;
        aliases.push(s.alias);
    })

    const sensorsToAdd = _.differenceBy(newSensors, oldDefn[xAxisId].sensors, 'alias');

    const yAxisOptions = [-2, -1, 1, 2];

    sensorsToAdd.forEach((sensor, index) => {
        if (!aliases.includes(sensor.alias)) {

            let sensorColor = sensor.color;
            if (_.isNil(sensorColor)) {
              sensorColor = getUniqueSeriesColor(updatedSensorList.map(s => s.color));
            }

            aliases.push(sensor.alias);
            updatedSensorList.push({
              ...sensor,
              displayName: sensor?.alias,
              color: sensorColor,
              isVisible: true,
              // We can have sensors that come from Sensor Groups
              // So respect the selected axis they have in the group, if that is available
              yAxisId: sensor.axisPosition || yAxisOptions[index],
              lineStyle: defaultLineStyle
            });
        }
    });

    definition.primary.sensors = updatedSensorList;
    definition.primary.trucks = Array(newSensors.length).fill(oldDefn.primary.defaultTruck);

    return definition;
}

/**
 * Given a Definition that returned only the sensor configuration, which comes from the sensor selector for the unit user chart card config panel, only pick the following properties from
 * the Primary chart: 
 *  sensors
 * @param {*} definition 
 */
const sanitizeDefinition = (definition) => {
  let sensors = _.cloneDeep(definition.primary.sensors);
  sensors = _.map(sensors, element => { return _.omit(element, ['targetUoms']) });
  return { sensors: sensors }
}

/**
* Given the type of dashboard we are on,
*/
const determineTruckFromDashboard = (truck, dashboard) => {
  switch (dashboard) {
    case ComponentTypes.PUMP_DASHBOARD:
      return truck;
    case ComponentTypes.FLEET_DASHBOARD:
      return {
        id: truck.datavanId,
        truckPid: truck.datavanPid,
        name: truck.datavanName,
        unitType: assetTypes.DATAVAN
      };
    }
}

export {
    updateDefn,
    sanitizeDefinition,
    normalizeSensorData,
    determineTruckFromDashboard
};
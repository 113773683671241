import { v4 } from 'uuid';
//mock set of fleets
let fleets = [
    {id:0, fleetName:"MOD2054", datavan:"MOD2054", fleetIdentifier:"5ec67f8c-4c80-419d-b97f-aa7ccad7d5b6", division: 'Wireline', active:false, communicationStatus:"Online", type: 'Test'},
    {id:1, fleetName:"DW1", datavan:"DW1", fleetIdentifier:"2yu66c9f-4c80-419d-b97f-aa7ccad7d5b6", division:'Wireline', active:false, communicationStatus:"Offline", type: 'Wireline'}
];
for (let i = 2; i < 100; i++){
  fleets.push({id:i, fleetName:`MOD${i.toString().padStart(4,'0')}`, datavan:`MOD${i.toString().padStart(4,'0')}`, fleetIdentifier: v4(), division:'Wireline', active:true, communicationStatus:"Online", type: 'Wireline'});
}

const fetchFleets = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                fleetManagement: fleets
            })
        }, 1000);
    });
};

const fetchOwners = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                ownersWithApiKey: [
                    {
                        id: 1,
                        ownerName: "owner A",
                        ownerApiKey: "apiKey A"
                    },
                    {
                        id: 2,
                        ownerName: "owner B",
                        ownerApiKey: "apiKey B"
                    },
                    {
                        id: 3,
                        ownerName: "owner C",
                        ownerApiKey: "apiKeyC"
                    },
                    {
                        id: 4,
                        ownerName: "owner D",
                        ownerApiKey: "apiKey D"
                    },
                    {
                        id: 5,
                        ownerName: "owner E",
                        ownerApiKey: "apiKey E"
                    },
                    {
                        id: 6,
                        ownerName: "owner F",
                        ownerApiKey: "apiKey F"
                    }
                ]
            })
        }, 1000);
    });
};

const fetchSaveFleet = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                saveFleet: {
                    id: 1,
                    fleetIdentifier: "44bfeae6-b241-41d6-bca8-dc3c9d44b3ad",
                    fleetName: "TestFleet2",
                    datavan: "dv3",
                    active: false,  //set to false to test out some specific cases
                    region: null,
                    division: null,
                    type: "Pumper Only"
                }
            })
        }, 1000);
    });
}

const fetchAssetInfoForTruck = (truckName) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                assetInfoForTruck: truckName === 'test1'? {
                    "assetId": 123456,
                    "assetCategory": "MDT Ops Laptop"
                } : null
            })
        }, 1000);
    });
}

export {
    fetchFleets,
    fetchOwners,
    fetchSaveFleet,
    fetchAssetInfoForTruck
}